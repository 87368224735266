import {
  VIRTUAL_STATUSES,
  STATUS_PENDING_ANSWER,
  STATUS_PENDING_PAYMENT,
  STATUS_PENDING_REALIZATION,
  STATUS_JOB_IN_PROGRESS,
  STATUS_JOB_DONE,
  VIRTUAL_STATUS_HISTORICAL,
  VIRTUAL_STATUS_IN_PROGRESS,
  VIRTUAL_STATUS_MISSED,
  TRACKING_LM_THEME,
  TRACKING_BOULANGER_THEME,
  TRACKING_QUOTATIS_THEME,
  JOB_PICTURE_ORIGINS,
} from 'constants/job'

import { TRAILING_SLASH } from 'constants/routes'

export const getChildStatuses = status => VIRTUAL_STATUSES[status] || [status]

export const getIdFromJob = job => {
  const strID = job['@id'].split('/')
  return strID[strID.length - 1]
}
export const getIdFromJobIri = jobIri => {
  const strID = jobIri.split('/')
  return strID[strID.length - 1]
}

export const convertCentsToEuros = priceInCents => priceInCents / 100

export const stripTrailingSlash = str => str.replace(TRAILING_SLASH, '')

export const getStatusUrls = baseUrl => {
  const jobConsideredInProgessUrlStart = `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`
  const jobInHistoricalUrlStart = `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`

  return [
    {
      urlStart: baseUrl,
      url: baseUrl,
      status: STATUS_PENDING_ANSWER,
    },
    {
      urlStart: jobConsideredInProgessUrlStart,
      url: `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`,
      status: STATUS_PENDING_REALIZATION,
    },
    {
      urlStart: jobConsideredInProgessUrlStart,
      url: `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`,
      status: STATUS_JOB_IN_PROGRESS,
    },
    {
      urlStart: jobConsideredInProgessUrlStart,
      url: `${baseUrl}/${VIRTUAL_STATUS_IN_PROGRESS}`,
      status: STATUS_PENDING_PAYMENT,
    },
    {
      urlStart: baseUrl,
      status: VIRTUAL_STATUS_HISTORICAL,
      child: [
        {
          urlStart: jobInHistoricalUrlStart,
          url: `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`,
          status: STATUS_JOB_DONE,
        },
        {
          urlStart: jobInHistoricalUrlStart,
          url: `${baseUrl}/${VIRTUAL_STATUS_HISTORICAL}`,
          status: VIRTUAL_STATUS_MISSED,
        },
      ],
    },
  ]
}

export const areAllPastTimeslots = job => {
  const { timeslots } = job

  const now = new Date()
  now.setHours(0, 0, 0, 0)

  let result = true

  timeslots.forEach(timeslot => {
    const dateStartTime = new Date(timeslot.startTime)
    dateStartTime.setHours(0, 0, 0, 0)

    if (dateStartTime >= now) {
      result = false
    }
  })

  return result
}

export const partnerThemeName = partner => {
  switch (partner) {
    case TRACKING_LM_THEME:
      return TRACKING_LM_THEME.toLowerCase()
    case TRACKING_BOULANGER_THEME:
      return TRACKING_BOULANGER_THEME
    default:
      return TRACKING_QUOTATIS_THEME
  }
}
export const partnerLink = partner => {
  switch (partner) {
    case TRACKING_LM_THEME:
      return '/vitrine/logo/leroy_merlin.svg'
    case TRACKING_BOULANGER_THEME:
      return '/vitrine/logo/Boulanger_favicon.svg'
    default:
      return '/vitrine/logo/logo_baseline_vertical.svg'
  }
}
export const partnerLogo = partner => {
  switch (partner) {
    case TRACKING_LM_THEME:
      return '/vitrine/logo/leroy_merlin_small.png'
    case TRACKING_BOULANGER_THEME:
      return '/vitrine/logo/Boulanger_small.png'
    default:
      return '/vitrine/logo/quotatis_small.png'
  }
}
export const partnerAlt = partner => {
  switch (partner) {
    case TRACKING_LM_THEME:
      return 'Leroy Merlin'
    case TRACKING_BOULANGER_THEME:
      return 'Boulanger'
    default:
      return 'Quotatis'
  }
}

export const convertEngineFilesForFileUploader = files => {
  if (!Array.isArray(files)) {
    return []
  }

  return files
    .filter(
      jobFile =>
        jobFile['@type'] === 'JobPicture' &&
        JOB_PICTURE_ORIGINS.HO.includes(jobFile.origin),
    )
    .map(jobFile => {
      if (jobFile.contentType === null) {
        return {
          ...jobFile,
        }
      }

      let type = jobFile.contentType.split('/')
      type = type[1] === 'pdf' ? 'pdf' : 'image'

      return {
        ...jobFile,
        type,
      }
    })
}

export const checkboxArrayToObject = (field, specificField) => {
  const resultField = {}

  Object.keys(field).forEach(key => {
    if (
      (field[key] === true && key !== `${specificField}`) ||
      typeof field[key] === 'string'
    ) {
      resultField[key] = field[key]
    }
  })
  return resultField
}

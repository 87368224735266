import { createSelector } from 'reselect'
import jwtDecode from 'jwt-decode'
import { ACCESS_TOKEN, ID_TOKEN, SESSION_STATUS_DEFAULT } from 'constants/auth'

export const initialState = {
  loggedIn: null,
  appOperational: true,
  phoneNumber: null,
  isCodeSent: false,
  isCodeFailed: false,
  // le temps que le store soit monté
  // les token ne sont pas chargés
  // le user est redirigé sur le login
  [ID_TOKEN]: localStorage.getItem(ID_TOKEN) || null,
  [ACCESS_TOKEN]: localStorage.getItem(ACCESS_TOKEN) || null,
  checkSessionStatus: SESSION_STATUS_DEFAULT,
}

export const isAppOperational = (state = initialState) => state.appOperational
export const isLoggedIn = (state = initialState) =>
  !!state[ID_TOKEN] && !!state[ACCESS_TOKEN]
export const getCheckSessionStatus = (state = initialState) =>
  state.checkSessionStatus
export const getPhoneNumber = (state = initialState) => state.phoneNumber
export const isCodeSent = (state = initialState) => state.isCodeSent
export const isCodeFailed = (state = initialState) => state.isCodeFailed
const selectIdToken = (state = initialState) => state[ID_TOKEN]
export const selectIdTokenDecoded = createSelector(selectIdToken, idToken =>
  idToken ? jwtDecode(idToken) : null,
)
export const selectIdTokenExpAt = (state = initialState) =>
  selectIdTokenDecoded(state)?.exp

import {
  generateSimpleAction,
  generateRequestActions,
  createRequestTypes,
} from 'utils/actionHelpers'

export const AUTH_LOGIN = createRequestTypes('AUTH_LOGIN')
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const AUTH_SET_LOGGED_IN = 'AUTH_SET_LOGGED_IN'
export const AUTH_SET_APP_OPERATIONAL = 'AUTH_SET_APP_OPERATIONAL'
export const AUTH_RENEW_TOKEN = 'AUTH_RENEW_TOKEN'
export const AUTH_PASSWORDLESS_SMS_START = createRequestTypes(
  'AUTH_PASSWORDLESS_SMS_START',
)
export const AUTH_PASSWORDLESS_SMS_LOGIN = createRequestTypes(
  'AUTH_PASSWORDLESS_SMS_LOGIN',
)
export const CHECK_HASH_TOKEN = 'CHECK_HASH_TOKEN'
export const NO_JOB_REDIRECT = 'NO_JOB_REDIRECT'
export const CHECK_SESSION_STATUS = 'CHECK_SESSION_STATUS'

export const authLogin = generateRequestActions(AUTH_LOGIN)

export const authLogout = generateSimpleAction(AUTH_LOGOUT)
export const setCheckSessionStatus = generateSimpleAction(CHECK_SESSION_STATUS)
export const setAppOperational = generateSimpleAction(AUTH_SET_APP_OPERATIONAL)
export const authPasswordlessSmsStart = generateRequestActions(
  AUTH_PASSWORDLESS_SMS_START,
)
export const authPasswordlessSmsLogin = generateRequestActions(
  AUTH_PASSWORDLESS_SMS_LOGIN,
)
export const checkHashToken = generateSimpleAction(CHECK_HASH_TOKEN)
export const noJobRedirect = generateSimpleAction(NO_JOB_REDIRECT)

export const SET_AUTH_TOKENS = 'SET_AUTH_TOKENS'
export const doSetAuthTokens = generateSimpleAction(SET_AUTH_TOKENS)

import axios from 'axios'
import { frontApi } from '../../config'
import { interceptor } from 'initializers/initializeAxios'

const client = axios.create({
  baseURL: frontApi.url,
  headers: {
    'Content-Type': 'application/ld+json',
  },
})

client.interceptors.request.use(interceptor)

export default client

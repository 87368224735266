import {
  PRO_CREATE,
  PRO_AUTH_SET_LOGGED_IN,
  PRO_AUTH_SET_FIRM_ID,
  PRO_AUTH_SET_NICKNAME,
  PRO_AUTH_SET_EMAIL,
  PRO_AUTH_SET_ROLES,
  PRO_SET_SPN,
  PRO_SET_TABS,
  PRO_SET_PRO_FORM_REGISTRATION_STEP,
  SET_REGISTRATION_NUMBER_DATA,
} from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case PRO_CREATE.SUCCESS:
      return {
        ...state,
        redirecting: true,
      }
    case PRO_AUTH_SET_LOGGED_IN:
      return {
        ...state,
        auth: {
          ...state.auth,
          isLoggedIn: action.payload,
        },
      }
    case PRO_AUTH_SET_FIRM_ID:
      return {
        ...state,
        auth: {
          ...state.auth,
          firmId: action.payload,
        },
      }
    case PRO_AUTH_SET_NICKNAME:
      return {
        ...state,
        auth: {
          ...state.auth,
          nickname: action.payload,
        },
      }
    case PRO_AUTH_SET_EMAIL:
      return {
        ...state,
        auth: {
          ...state.auth,
          email: action.payload,
        },
      }
    case PRO_AUTH_SET_ROLES:
      return {
        ...state,
        auth: {
          ...state.auth,
          roles: action.payload,
        },
      }
    case PRO_SET_SPN:
      return {
        ...state,
        spn: action.payload,
      }
    case PRO_SET_TABS:
      return {
        ...state,
        tabs: action.tabs,
      }
    case SET_REGISTRATION_NUMBER_DATA:
      return {
        ...state,
        registrationNumberData: action.payload,
      }
    case PRO_SET_PRO_FORM_REGISTRATION_STEP:
      return {
        ...state,
        proFormRegistrationStep: action.payload,
      }
    default: {
      return state
    }
  }
}

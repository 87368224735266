import { initialState } from './selectors'
import {
  AUTH_LOGOUT,
  AUTH_SET_LOGGED_IN,
  AUTH_SET_APP_OPERATIONAL,
  AUTH_PASSWORDLESS_SMS_START,
  AUTH_PASSWORDLESS_SMS_LOGIN,
  SET_AUTH_TOKENS,
  CHECK_SESSION_STATUS,
} from './actions'

export default (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_LOGGED_IN: {
      return {
        ...state,
        loggedIn: action.payload,
      }
    }
    case AUTH_SET_APP_OPERATIONAL: {
      return {
        ...state,
        appOperational: action.payload,
      }
    }
    case AUTH_LOGOUT: {
      return {
        ...initialState,
      }
    }
    case AUTH_PASSWORDLESS_SMS_START.REQUEST: {
      return {
        ...state,
        phoneNumber: action.phoneNumber,
        isCodeFailed: false,
      }
    }
    case AUTH_PASSWORDLESS_SMS_START.SUCCESS: {
      return {
        ...state,
        isCodeSent: true,
      }
    }
    case AUTH_PASSWORDLESS_SMS_START.FAILURE: {
      return {
        ...state,
        isCodeSent: false,
      }
    }
    case AUTH_PASSWORDLESS_SMS_LOGIN.REQUEST: {
      return {
        ...state,
        isCodeFailed: false,
      }
    }
    case AUTH_PASSWORDLESS_SMS_LOGIN.FAILURE: {
      return {
        ...state,
        isCodeFailed: true,
      }
    }
    case SET_AUTH_TOKENS: {
      const { idToken, accessToken } = action.payload
      return {
        ...state,
        idToken,
        accessToken,
      }
    }
    case CHECK_SESSION_STATUS: {
      return {
        ...state,
        checkSessionStatus: action.payload,
      }
    }
    default: {
      return state
    }
  }
}

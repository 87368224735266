import { put, select } from 'redux-saga/effects'
import uuidv4 from 'uuid/v4'

import { addNotifications } from '../../store/notifications/actions'
import { fromContext } from '../../store/selectors'
import translations from '../../i18n'

/**
 * Return a new string in witch all matches to a pattern like %pattern% are replaced
 *
 *  Example:
 *    const str = 'My name is %fistname% %name%'
 *    const replacer = {
 *      firstname: 'Joe',
 *      name: 'Dalton',
 *    }
 *
 *    replace(str, replacer) will return "My name is Joe Dalton"
 */
const replace = (str, replacer = {}) => {
  let strTransformed = str

  Object.keys(replacer).forEach(key => {
    strTransformed = strTransformed.replace(`%${key}%`, replacer[key])
  })

  return strTransformed
}

export default function* notify(
  title,
  message,
  status = 'success',
  titleValues = {},
  messageValues = {},
  autoClose = true,
  id,
) {
  const supportedStatuses = [
    'default',
    'info',
    'success',
    'warning',
    'error',
    'loading',
  ]

  if (!supportedStatuses.includes(status)) {
    throw new Error(
      `Status: ${status} is not defined, use one of : ${supportedStatuses.join(
        ', ',
      )}`,
    )
  }

  const language = yield select(fromContext.getLang)
  const titleTranslated = title != null ? translations[language][title] : ''
  const messageTranslated =
    message != null ? translations[language][message] : ''

  yield put(
    addNotifications({
      id: id || uuidv4(),
      title: replace(titleTranslated, titleValues),
      message: replace(messageTranslated, messageValues),
      status,
      autoClose,
    }),
  )

  return null
}

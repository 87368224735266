import {
  CHECK_IN_HO_STATUS_OK,
  CHECK_IN_HO_STATUS_KO,
  CHECK_IN_HO_STATUS_NA,
} from 'constants/job'

export const getDisplayableQuestions = (questions, formValues) =>
  questions.filter(question => {
    if (!question.displayConditions) {
      return true
    }

    if (formValues === undefined) {
      return false
    }

    if (
      formValues[question.displayConditions[0].question] &&
      formValues[question.displayConditions[0].question] !==
        question.displayConditions[0].answer
    ) {
      return false
    }

    return true
  })

export const formatVerifyConditionsData = (data, requiredProducts) => {
  let productAvailableStatus = data.product_available
    ? CHECK_IN_HO_STATUS_OK
    : CHECK_IN_HO_STATUS_KO

  if (requiredProducts === false) {
    productAvailableStatus = CHECK_IN_HO_STATUS_NA
  }

  return [
    {
      code: 'product_available',
      status: productAvailableStatus,
      comment: data.product_available_comment
        ? data.product_available_comment
        : null,
    },
    {
      code: 'product_condition',
      status: !data.product_available
        ? CHECK_IN_HO_STATUS_NA
        : data.product_condition
        ? CHECK_IN_HO_STATUS_OK
        : CHECK_IN_HO_STATUS_KO,
      comment: data.product_condition_comment
        ? data.product_condition_comment
        : null,
    },
    {
      code: 'pictures_sent',
      status: data.pictures_sent
        ? CHECK_IN_HO_STATUS_OK
        : CHECK_IN_HO_STATUS_KO,
    },
    {
      code: 'job_location_accessibility',
      status: data.job_location_accessibility
        ? CHECK_IN_HO_STATUS_OK
        : CHECK_IN_HO_STATUS_KO,
    },
    {
      code: 'ho_presence_on_job_day',
      status: data.ho_presence_on_job_day
        ? CHECK_IN_HO_STATUS_OK
        : CHECK_IN_HO_STATUS_KO,
    },
  ]
}

export const verifyFieldsForm = checkInConditions =>
  Object.keys(checkInConditions).map(value => checkInConditions[value])

import { initialState } from './selectors'
import {
  LEAD_SALE_FIRMS,
  FIRM_CURRENT,
  FIRM_DETAILS,
  FIRM_REVIEWS,
  FIRM_UPDATE,
  HASHID_DECODE,
  FIRM_PLACE_AUTO_COMPLETE_LOCATION,
  FIRM_SET_IS_LOGIN_REQUEST,
  FIRM_PLACE_CLEAR_AUTO_COMPLETE_LOCATION,
  FIRM_SET_VALIDATION_EMAIL_TOKEN_STATUS,
  FIRM_UPDATE_LEGAL_INFO,
  FIRM_SET_CANDIDATE_PACKAGE_VALIDATION_ERROR,
  FIRM_GET_SINGLE_FILE,
  REMOVE_REGISTER_FILE,
  FIRM_SET_FIRM_FILES,
  GET_FIRM_BY_PRO_ID,
  UPDATE_FIRM_DETAILS,
  UPDATE_FIRM_USER_DETAILS,
  FIRM_VALIDATE_EMAIL,
} from './actions'

export default (state = initialState, action) => {
  switch (action.type) {
    case LEAD_SALE_FIRMS.SUCCESS:
      return {
        ...state,
        [action.payload['@id']]: action.payload,
      }
    case FIRM_DETAILS.SUCCESS:
      return {
        ...state,
        [action.payload['@id']]: action.payload,
      }
    case FIRM_UPDATE.SUCCESS:
      return {
        ...state,
        [action.payload['@id']]: action.payload,
      }
    case FIRM_REVIEWS.SUCCESS:
      return {
        ...state,
        reviews: {
          ...state.reviews,
          [action.payload['@id']]: action.payload['hydra:member'],
        },
      }
    case FIRM_CURRENT.REQUEST:
      return {
        ...state,
        currentFirmId: action.payload,
      }
    case HASHID_DECODE.SUCCESS:
      return {
        ...state,
        hashids: {
          ...state.hashids,
          [action.actionParams]: action.payload,
        },
      }
    case FIRM_UPDATE_LEGAL_INFO.REQUEST:
      return {
        ...state,
        data: action.payload,
      }
    case FIRM_PLACE_AUTO_COMPLETE_LOCATION:
      return {
        ...state,
        autoCompleteLocation: action.payload,
      }
    case FIRM_SET_IS_LOGIN_REQUEST:
      return {
        ...state,
        isLoginRequest: action.isLoginRequest,
      }
    case FIRM_PLACE_CLEAR_AUTO_COMPLETE_LOCATION: {
      const { autoCompleteLocation, ...rest } = state
      return {
        ...rest,
      }
    }
    case FIRM_SET_VALIDATION_EMAIL_TOKEN_STATUS:
      return {
        ...state,
        firmValidationEmailStatus: action.payload,
      }
    case FIRM_SET_CANDIDATE_PACKAGE_VALIDATION_ERROR:
      return {
        ...state,
        candidatePackageValidationError: action.payload,
      }
    case FIRM_SET_FIRM_FILES:
      return {
        ...state,
        firmFiles: {
          ...state.firmFiles,
          [action.payload.id]: {
            ...state.firmFiles[action.payload.id],
            type: action.payload.type,
            name: action.payload.name,
          },
        },
      }
    case FIRM_GET_SINGLE_FILE.SUCCESS:
      return {
        ...state,
        firmFiles: {
          ...state.firmFiles,
          [action.payload['@id']]: action.payload,
        },
      }
    case REMOVE_REGISTER_FILE.SUCCESS: {
      const toDelete = `/api/firm_files/${action.actionParams.idFile}`
      const { [toDelete]: deletedFile, ...newFirmFiles } = state.firmFiles

      return {
        ...state,
        firmFiles: newFirmFiles,
      }
    }
    case GET_FIRM_BY_PRO_ID.SUCCESS: {
      return {
        ...state,
        firmOfPro: action.payload,
      }
    }
    case UPDATE_FIRM_DETAILS.SUCCESS: {
      return {
        ...state,
        [action.payload['@id']]: action.payload,
      }
    }
    case UPDATE_FIRM_USER_DETAILS.SUCCESS: {
      const { users } = state[action.payload.firm]
      const currentUserId = users.findIndex(
        user => user['@id'] === action.payload['@id'],
      )

      users[currentUserId] = {
        mobilePhone: action.payload.mobilePhone,
        fixedPhone: action.payload.fixedPhone,
        gender: action.payload.gender,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        email: action.payload.email,
      }

      return {
        ...state,
        [action.payload.firm]: {
          ...state[action.payload.firm],
          users,
        },
      }
    }
    case FIRM_VALIDATE_EMAIL.SUCCESS: {
      return {
        ...state,
        packageStatusV2: action.payload.packageStatusV2,
        quotationStatus: action.payload.quotationStatus,
      }
    }
    default: {
      return state
    }
  }
}

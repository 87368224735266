import auth0 from 'auth0-js'
import config, { trackingLocales } from 'config'
import { signInErrors } from './error'

class Auth0Tracking {
  constructor() {
    this.auth0conf = { ...config.auth0Tracking }

    this.renewTokenTimeoutIds = []
    this.auth = new auth0.WebAuth({
      domain: this.auth0conf.domain,
      audience: this.auth0conf.audience,
      clientID: this.auth0conf.clientID,
      responseType: 'token id_token',
      scope: 'openid offline_access',
      redirectUri: this.auth0conf.redirectUri,
    })
  }

  authPasswordlessSmsStart(phoneNumber) {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          connection: 'sms',
          send: 'code',
          phoneNumber,
        },
        (err, response) => {
          if (err) {
            reject(err)
            return
          }
          resolve(response)
        },
      )
    }).catch(error => Promise.reject(signInErrors[error.code]))
  }

  authPasswordlessSmsLogin(
    verificationCode,
    phoneNumber,
    trackingPage,
    partner,
  ) {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessLogin(
        {
          connection: 'sms',
          phoneNumber,
          verificationCode,
          redirectUri: `${trackingLocales['fr-FR'].url}/login?page=${trackingPage}&partner=${partner}`,
          scope: 'openid phone',
        },
        (err, response) => {
          if (err) {
            reject(err)
            return
          }
          resolve(response)
        },
      )
    }).catch(error => Promise.reject(signInErrors[error.code]))
  }

  parseHashFromPasswordless() {
    return new Promise(resolve => {
      this.auth.parseHash(
        { hash: window.location.hash },
        (error, authResult) => {
          if (error) {
            resolve(null)
          }
          resolve(authResult)
        },
      )
    }).catch(error => Promise.reject(error))
  }

  async checkSession() {
    return new Promise((resolve, reject) => {
      this.auth.checkSession(
        {
          audience: this.auth0conf.audience,
          scope: 'openid offline_access phone',
        },
        (err, response) => {
          if (err) {
            const { original, ...error } = err
            reject(error)

            return
          }

          resolve(response)
        },
      )
    }).catch(error => error)
  }
}

export default new Auth0Tracking()

export const ANSWER_TYPE_OTHER = 'other'
export const LABEL_OTHER = 'pro.onboarding_form.other'
export const NO_SCORE = 'no_score'
export const CONFIGURATION_PACKAGE_FORM = 'CONFIGURATION_PACKAGE_FORM'

export const STORAGE_PACKAGES_CATEGORY = 'Rangement et dressing'
export const STORAGE_PACKAGES_NAME = 'storage_dressing'
export const CARPENTRY_PACKAGES_CATEGORY = 'Menuiserie'
export const CARPENTRY_PACKAGES_NAME = 'carpentry'
export const TERRACE_PACKAGES_CATEGORY = 'Terrasse et jardin'
export const TERRACE_PACKAGES_NAME = 'terrace'
export const BATHROOM_PACKAGES_CATEGORY = 'Salle de bains'
export const BATHROOM_PACKAGES_NAME = 'bathroom'
export const KITCHEN_PACKAGES_CATEGORY = 'Cuisine'
export const KITCHEN_PACKAGES_NAME = 'kitchen'
export const HEATING_PACKAGES_CATEGORY = 'Chauffage et plomberie'
export const HEATING_PACKAGES_NAME = 'heating'
export const ELECTRICITY_PACKAGES_CATEGORY = 'Électricité et domotique'
export const ELECTRICITY_PACKAGES_NAME = 'electricity'
export const HARDWARE_STORE_PACKAGES_CATEGORY = 'Quincaillerie'
export const HARDWARE_STORE_PACKAGES_NAME = 'hardware_store'
export const GROUND_PACKAGES_CATEGORY = 'Sol'
export const GROUND_PACKAGES_NAME = 'ground'
export const OTHERS_PACKAGES_CATEGORY = 'Autre'
export const OTHERS_PACKAGES_NAME = 'other'
export const ZONE_NAME = 'postcodes'
export const VAT_NAME = 'vatNumber'
export const SWIFT_NAME = 'swiftCode'
export const IBAN_NAME = 'internationalBankAccountNumber'
export const MATCHING_OPTION_DEPARTMENT = 'MATCHING_OPTION_DEPARTMENT'
export const PRODUCT_TYPES = {
  quotation: 'service_quotation',
  package: 'service_package',
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import 'initializers/initializeAxios'
import { initializeFeatureFlags } from 'feature-flag-consumer-js-lib'
import * as Sentry from '@sentry/react'
import { CONSUMER_HO_DESKTOP } from './constants/features'
import client from './services/httpClient/frontApiClient'
import configureStore from './store/store'
import App from './App'
import { env, sentry } from './config'

Sentry.init({
  dsn: sentry.url,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
    }),
    Sentry.replayIntegration(),
  ],
  environment: env,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: sentry.tracesSampleRate,
  replaysSessionSampleRate: sentry.replaysSessionSampleRate,
  replaysOnErrorSampleRate: 1.0,
  normalizeDepth: 10,
})

const store = configureStore()

const initializeApplication = async () => {
  try {
    await initializeFeatureFlags(store, CONSUMER_HO_DESKTOP, client)
  } catch (e) {
    console.error(e)
  }

  const root = createRoot(document.getElementById('root'))
  root.render(
    <Provider store={store}>
      <BrowserRouter>
        <App store={store} />
      </BrowserRouter>
    </Provider>,
  )
}

initializeApplication()

export default store

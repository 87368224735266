import { locales, defaultLocale } from '../config'

export const languages = ['fr', 'es']

export const LOCALE_TO_LANG = {
  fr: 'fr-FR',
  es: 'es-ES',
}

const LANG_TO_CURRENCY = {
  fr: 'EUR',
  es: 'EUR',
}

export const getCurrencyFromLang = lang => LANG_TO_CURRENCY[lang]

export const getLocaleFromLang = lang => LOCALE_TO_LANG[lang]

export const getLangFromHostname = hostname => {
  if (hostname.match(/\bes\b/)) {
    return 'es'
  }

  return 'fr'
}

export const getLocaleFromHostname = hostname =>
  getLocaleFromLang(getLangFromHostname(hostname))
export const getUrlFromLocale = locale => locales[locale].url
export const getProUrlFromLocale = locale =>
  locales[locale].pro.urls.home.startsWith('http')
    ? locales[locale].pro.urls.home
    : locales[locale].url + locales[locale].pro.urls.home
export const getLangFromLocale = locale => locale.split('-')[0]
export const getCountryFromLocale = locale => locale.split('-')[1]
export const getDefaultCountry = () => {
  const locale = global.window
    ? getLocaleFromHostname(window.location.hostname)
    : defaultLocale

  return getCountryFromLocale(locale)
}

export const TEST_TITLE_BEFORE_CODE_SENDING =
  'tracking_authentication.phone_authentication'
export const TEST_TITLE_AFTER_CODE_SENDING =
  'tracking_authentication.phone_verification'
export const TEST_PARAGRAPH_BEFORE_CODE_SENDING =
  'tracking_authentication.phone_message'
export const TEST_PARAGRAPH_AFTER_CODE_SENDING =
  'tracking_authentication.code_message'
export const TEST_INPUT_ID_BEFORE = 'phoneNumber'
export const TEST_INPUT_ID_AFTER = 'code'
export const TEST_BUTTON_TRACKING_ID_BEFORE =
  'tracking_authentication.login.button'
export const TEST_BUTTON_TRACKING_ID_AFTER =
  'tracking_authentication.code.button'
export const ID_TOKEN = 'idToken'
export const ACCESS_TOKEN = 'accessToken'
export const SESSION_STATUS_DEFAULT = 'default'
export const SESSION_STATUS_PENDING = 'pending'
export const SESSION_STATUS_SUCCESS = 'success'

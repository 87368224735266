import PhoneNumber from 'awesome-phonenumber'
import { getDefaultCountry } from 'utils/locale'

const getPhoneNumber = (phoneNumber, country = null) => {
  try {
    return new PhoneNumber(phoneNumber, country || getDefaultCountry())
  } catch (e) {
    return null
  }
}

export const getInternationalPhoneNumber = (phoneNumber, country = null) => {
  const pn = getPhoneNumber(phoneNumber, country)
  if (!pn) {
    return null
  }

  return pn.getNumber('international').replace(/\s/g, '')
}

export const phoneNumberValidator = phoneNumber => {
  const pn = getPhoneNumber(phoneNumber)
  const errorMessage = {
    id: 'tracking_authentication.phone_error',
    values: {},
  }

  if (!pn) {
    return errorMessage
  }

  return pn.isValid() ? undefined : errorMessage
}

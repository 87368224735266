import { defaultLocale } from '../../config'
import {
  POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG,
  POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG,
  PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG,
} from '../../constants/firm'
import { POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG } from '../../constants/job'
import {
  getLocaleFromHostname,
  getLangFromLocale,
  getCountryFromLocale,
} from '../../utils/locale'

export const locale = global.window
  ? getLocaleFromHostname(window.location.hostname)
  : defaultLocale

export const initialState = {
  country: getCountryFromLocale(locale),
  lang: getLangFromLocale(locale),
  locale: 'fr-FR',
  mainNavigation: false,
  accountNavigation: false,
  signInPopin: false,
  signInHoPopin: false,
  signInProPopin: false,
  signInSignUpHoPopin: {
    enabled: false,
    mode: 'signup',
  },
  pathContext: 'default',
  pathNameOrigin: null,
  phoneValidationPopin: {
    enabled: false,
    mode: 'phone',
  },
  emailValidationPopin: false,
  initialQueryParams: {},
  searchModalIsOpen: false,
  cookiesBannerIsOpen: false,
  [POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG]: false,
  [POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG]: false,
  firmPicturesEditPopinDisplayed: false,
  [PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG]: false,
  [POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG]: false,
}

export const getLang = state => state.lang
export const getMainNavigation = state => state.mainNavigation
export const getAccountNavigation = state => state.accountNavigation
export const getSignInPopin = state => state.signInPopin
export const getSignInHoPopin = state => state.signInHoPopin
export const getSignInProPopin = state => state.signInProPopin
export const getSignInSignUpHoPopin = state => state.signInSignUpHoPopin.enabled
export const getSignInSignUpHoPopinMode = state =>
  state.signInSignUpHoPopin.mode
export const getPathContext = state => state.pathContext
export const getPathNameOrigin = state => state.pathNameOrigin
export const getPhoneValidationPopin = state =>
  state.phoneValidationPopin.enabled
export const getPhoneValidationPopinMode = state =>
  state.phoneValidationPopin.mode
export const getEmailValidationPopin = state => state.emailValidationPopin
export const isPopin = state =>
  [
    state.mainNavigation,
    state.accountNavigation,
    state.signInPopin,
    state.signInSignUpHoPopin.enabled,
    state.phoneValidationPopin.enabled,
    state.emailValidationPopin,
    state.searchModalIsOpen,
    state[POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG],
    state.firmPicturesEditPopinDisplayed,
    state[POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG],
    // state[PRO_ACC_NAV_DISPLAY_FLAG],
    state[PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG],
    state[POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG],
  ].some(value => value)
export const getCountry = state => state.country
export const getLocale = state => `${state.lang}-${state.country}`
export const getInitialQueryParams = state => state.initialQueryParams
export const getPartner = state => state.partner
export const searchModalIsOpen = state => state.searchModalIsOpen
export const cookiesBannerIsOpen = state => state.cookiesBannerIsOpen
export const ratingsAndReviewsEnabled = state => getLang(state) === 'es'
export const isFirmPicturesEditPopinDisplayed = state =>
  state.firmPicturesEditPopinDisplayed
export const isPopinDisplayed = (state, popInDisplayedFlag) =>
  state[popInDisplayedFlag]

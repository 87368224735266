const DEFAULT_ENV = 'qa'

function getEnvironmentFromUrl(url, service, domain) {
  if (!url) {
    return 'mocks'
  }

  const pattern = new RegExp(`${service}\\.(\\w+)\\.${domain}`)
  const match = url.match(pattern)
  if (match) {
    const envUrl = match[1]
    const env =
      ['qa', 'qa1', 'preprod', 'local'].find(el => el === envUrl) || DEFAULT_ENV
    return env === 'local' ? 'development' : env
  }

  return 'prod'
}

module.exports = getEnvironmentFromUrl

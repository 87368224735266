import { getSpnFromCookie } from 'utils/spn'
import { ROLES } from 'constants/roles'

export const initialState = {
  redirecting: false,
  proFormRegistrationStep: 1,
  auth: {
    isLoggedIn: false,
    firmId: '',
    nickname: '',
    roles: [],
  },
  spn: getSpnFromCookie(),
  tabs: {},
}

export const isRedirecting = state => state.redirecting
export const isLoggedIn = state => state.auth.isLoggedIn
export const getSpn = state => state.spn
export const getTabs = state => state.tabs
export const getFirmId = state => state.auth.firmId
export const getNickname = state => state.auth.nickname
export const getEmail = state => state.auth.email
export const getRoles = state => state.auth.roles
export const getProFormRegistrationStep = state => state.proFormRegistrationStep
export const getRegistrationNumberData = state => state.registrationNumberData
export const hasRoleAdmin = state =>
  getRoles(state).some(role => role === ROLES.ROLE_ADMIN)

export * from './auth/actions'
export * from './context/actions'
export * from './firm/actions'
export * from './routing/actions'
export * from './pro/actions'
export * from './hoauth/actions'
export * from './job/actions'
export * from './status/actions'
export * from './notifications/actions'

export const CLIENT_INITIATED = 'CLIENT_INITIATED'

export const clientInitiated = () => ({
  type: CLIENT_INITIATED,
})

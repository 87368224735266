export default search => {
  const decodedPath = decodeURI(search.substring(1))
  if (!decodedPath) {
    return {}
  }

  return Object.assign(
    ...decodedPath
      .replace(/"/g, '\\"')
      .split('&')
      .map(keyValue => keyValue.split('='))
      .map(keyValueTupple => ({ [keyValueTupple[0]]: keyValueTupple[1] })),
  )
}

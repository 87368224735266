import { fork, all } from 'redux-saga/effects'
import auth from './auth/sagas'
import context from './context/sagas'
import firm from './firm/sagas'
import job from './job/sagas'

export * from './auth/sagas'
export * from './context/sagas'
export * from './firm/sagas'

const sagas = [auth, context, firm, job]

export default function* () {
  yield all(sagas.map(fork))
}

import axios from 'axios'
import qs from 'qs'
import { api } from 'config'
import cloneDeep from 'lodash/cloneDeep'
import { ACCESS_TOKEN, ID_TOKEN } from 'constants/auth'
import { getLangFromLocale } from 'utils/locale'
import { locale } from 'store/context/selectors'

axios.defaults.baseURL = api.url
axios.defaults.paramsSerializer = qs.stringify
axios.defaults.headers.common = {
  ...axios.defaults.headers.common,
  Accept: 'application/ld+json',
  'Content-Type': 'application/json',
  'Accept-Language': getLangFromLocale(locale),
  apikey: api.kongApiKey,
  // Prevent API calls to be cached by shitty IE
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
}

export const interceptor = paramConfig => {
  const config = cloneDeep(paramConfig)
  if (config.skipAccessToken) {
    return config
  }

  const idToken = localStorage.getItem(ID_TOKEN)
  const accessToken = localStorage.getItem(ACCESS_TOKEN)

  if (idToken && accessToken) {
    config.headers.Identification = `Bearer ${idToken}`
    config.headers.Authorization = `Bearer ${accessToken}`
  }

  return config
}

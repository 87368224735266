import { put } from 'redux-saga/effects'
import {
  authLogout,
  doSetAuthTokens,
  setCheckSessionStatus,
} from 'store/auth/actions'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  SESSION_STATUS_DEFAULT,
  SESSION_STATUS_PENDING,
  SESSION_STATUS_SUCCESS,
} from 'constants/auth'
import { isMocks } from 'services/isMocks'
import authTracking from 'services/authTracking'
import notify from 'sagas/notify'

export const removeTokens = () => {
  localStorage.removeItem(ID_TOKEN)
  localStorage.removeItem(ACCESS_TOKEN)
}

export const saveTokens = function* ({ accessToken, idToken }) {
  yield put(
    doSetAuthTokens({
      payload: {
        idToken,
        accessToken,
      },
    }),
  )
}

export const bootstrapAuthFromLs = function* () {
  const idToken = localStorage.getItem(ID_TOKEN)
  const accessToken = localStorage.getItem(ACCESS_TOKEN)

  if (accessToken && idToken) {
    yield put(setCheckSessionStatus(SESSION_STATUS_PENDING))
    const authSession = yield authTracking.checkSession()
    if (authSession?.code) {
      yield put(authLogout({ payload: false }))
      yield* notify('', 'user.sign_in.token_expired', 'error')
    }

    yield put(
      doSetAuthTokens({
        payload: {
          idToken: authSession?.idToken || null,
          accessToken: authSession?.accessToken || null,
        },
      }),
    )
    yield put(
      setCheckSessionStatus(
        authSession?.code ? SESSION_STATUS_DEFAULT : SESSION_STATUS_SUCCESS,
      ),
    )
  }

  return isMocks || (!!idToken && !!accessToken)
}

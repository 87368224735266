/* eslint-disable no-use-before-define, import/newline-after-import, import/first */

/*
  Rewrites the selectors so that they select from the domain they are in
  eg: selectors declared in post/selectors.js are to be called with state.post
 */
const selectDomain = (selectors, domain) => {
  const transformed = {}
  Object.keys(selectors).forEach(key => {
    transformed[key] = (state, ...args) =>
      selectors[key](state[domain], ...args)
  })
  return transformed
}

import * as authSelectors from './auth/selectors'
export const fromAuth = selectDomain(authSelectors, 'auth')

import * as contextSelectors from './context/selectors'
export const fromContext = selectDomain(contextSelectors, 'context')

import * as firmSelectors from './firm/selectors'
export const fromFirm = selectDomain(firmSelectors, 'firm')

import * as routingSelectors from './routing/selectors'
export const fromRouting = selectDomain(routingSelectors, 'routing')

import * as statusSelectors from './status/selectors'
export const fromStatus = selectDomain(statusSelectors, 'status')

import * as proSelectors from './pro/selectors'
export const fromPro = selectDomain(proSelectors, 'pro')

import * as jobSelectors from './job/selectors'
export const fromJob = selectDomain(jobSelectors, 'job')

import * as notificationsSelectors from './notifications/selectors'
export const fromNotifications = selectDomain(
  notificationsSelectors,
  'notifications',
)

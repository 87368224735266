export const initialState = {
  loading: {},
  error: {},
}

export const getLoading = (state = initialState) => state.loading
export const getError = (state = initialState) => state.error
export const getRequested = (state = initialState) => state.requested

// These following selectors are to be used in a reducer as they take an actionTypes object as input
export const isLoading = (state = initialState, actionType) =>
  !!getLoading(state)[actionType]
export const hasError = (state = initialState, actionType) =>
  getError(state)[actionType]
export const hasBeenRequested = (state = initialState, actionType) =>
  getRequested(state)[actionType]

export const requestHasBeenSuccessful = (state = initialState, actionType) =>
  hasBeenRequested(state, actionType) && !hasError(state, actionType)

export const requestHasFailed = (state = initialState, actionType) =>
  hasBeenRequested(state, actionType) && hasError(state, actionType)

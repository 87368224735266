import {
  parse,
  format,
  isBefore,
  isAfter,
  isValid,
  parseISO,
  differenceInCalendarDays,
  getDay,
} from 'date-fns'

import Holidays from 'date-holidays'
import moment from 'moment-timezone'
import { startCase, omit } from 'lodash'

import { DATE_FORMAT } from 'constants/dateFormat'
import { fr } from 'date-fns/locale'

/** These values are extracted from
 *  https://github.com/date-fns/date-fns/blob/v2.0.0-alpha.11/src/locale/es/_lib/localize/index.js
 *  We need to do that in order to fix issues on the locale es on the date-fns@v2.0.0-alpha.11
 *  See https://github.com/date-fns/date-fns/blob/v2.0.0-alpha.11/src/locale/es/index.js
 */
const weekdayValuesES = [
  'domingo',
  'lunes',
  'martes',
  'miércoles',
  'jueves',
  'viernes',
  'sábado',
]
const monthValuesES = [
  'enero',
  'febrero',
  'marzo',
  'abril',
  'mayo',
  'junio',
  'julio',
  'agosto',
  'septiembre',
  'octubre',
  'noviembre',
  'diciembre',
]

const DATE_FNS_LOCALES = {
  fr,
  es: 'fakeDateFnsESLocale',
}

/**
 * @param language {string} 'fr'|'es'...
 * @returns {Object} { locale: dateFnsLocale }|{}
 */
export const getDateFnsLocale = language => {
  const dateFnsLocale = DATE_FNS_LOCALES[language]

  return (dateFnsLocale && { locale: dateFnsLocale }) || {}
}

/**
 * @param dateString {string}
 * @returns {Object} {new Date()}
 */
export const getUTCDate = (dateString = Date.now()) => {
  const date = new Date(dateString)

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
  )
}

/**
 * @param date {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {date}
 */
export const createDate = (date, dateFormat = DATE_FORMAT.FR_LONG) =>
  parse(date, dateFormat, new Date())

/**
 * @param value {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {boolean}
 */
export const isBeforeFormattedDate = (value, dateFormat) => {
  const date = dateFormat ? createDate(value, dateFormat) : value

  return isBefore(date, new Date())
}

/**
 * @param value {string} '05/04/2018'
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {boolean}
 */
export const isAfterFormattedDate = (value, dateFormat) => {
  const date = dateFormat ? createDate(value, dateFormat) : value

  return isAfter(date, new Date())
}

/**
 * @param value {string} 'Fri Feb 09 2018 01:00:00'
 * @param formatTo {string} 'dd/MM/yyyy'
 * @param formatFrom {string} 'yyyy-MM-dd'
 * @param formatOptions {Object} {locale: dateFnsLocale, ...}
 * @returns {string}
 */
export const transformDate = (
  value,
  formatTo = DATE_FORMAT.FR_LONG,
  formatFrom = DATE_FORMAT.ISO_INITIAL_TIMEZONE,
  formatOptions = {},
) => {
  const date = formatFrom ? createDate(value, formatFrom) : value

  const isValidDate = isValid(date)
  if (!isValidDate) {
    return false
  }

  if (formatOptions.startCase && formatOptions.locale) {
    let formatToFixed = formatTo
    let formatOptionsFixed = formatOptions
    if (formatOptions.locale === 'fakeDateFnsESLocale') {
      formatToFixed = formatTo
        .replace('EEEE', `'${weekdayValuesES[format(date, 'c') - 1]}'`)
        .replace('LLLL', `'${monthValuesES[format(date, 'L') - 1]}'`)

      formatOptionsFixed = omit(formatOptions, 'locale')
    }

    return startCase(format(date, formatToFixed, formatOptionsFixed))
  }

  return format(date, formatTo)
}

/**
 * @param value {string} example 16/12/2015
 * @param regex
 * @param dateFormat {string} 'dd/MM/yyyy'
 * @returns {boolean}
 */
export const isValidDate = (value, regex, dateFormat) => {
  if (!regex.test(value)) {
    return false
  }
  const date = dateFormat ? createDate(value) : value

  return isValid(date)
}

/**
 * @param isoDateString {string} example 2019-07-27T00:00:00+00:00
 * @returns {boolean}
 */
export const compareIsoDate = isoDateString => {
  const dateStart = new Date()
  const dateEnd = new Date(isoDateString)

  return dateStart.getTime() > dateEnd.getTime()
}

/**
 * @param isoDateString {string} example 2019-07-27T00:00:00+00:00
 * @returns {date}
 */
export const formatDateInStartCase = (date, language) => {
  const dateFnsLocale = getDateFnsLocale(language)

  const formattingOptions = {
    ...dateFnsLocale,
    startCase: true,
  }

  return transformDate(
    date,
    DATE_FORMAT.DAY_OF_WEEK_MONTH_YEAR,
    DATE_FORMAT.ISO_INITIAL_TIMEZONE,
    formattingOptions,
  )
}

/**
 * @param date {string} example 2019-07-27T00:00:00+00:00
 * @param countryCode {string} example FR
 * @returns {date}
 */
export const getTimeFromDate = (date, countryCode) =>
  moment(`${date.split('+')[0]}Z`)
    .tz(DATE_FORMAT.TIMEZONE[countryCode])
    .format(DATE_FORMAT.TIME_ONLY)

/**
 * @param start {string} example 2019-07-27T00:00:00+00:00
 * @param end {string|null} example 2019-07-27T00:00:00+00:00 or null
 * @param absolute {boolean} example true or false
 * @returns {number}
 */
export const dateDiffInDays = (start, end = null, absolute = true) => {
  const diff = differenceInCalendarDays(
    end ? new Date(end) : new Date(),
    new Date(start),
  )

  return Math.ceil(absolute ? Math.abs(diff) : diff)
}

export const dateFormatter = date =>
  typeof date === 'string' ? parseISO(date) : date

/**
 * Convert hours (float) to HHhMM time format
 * @param hours
 * @returns {string}
 */
export const timeConvert = hours => {
  const date = new Date(0, 0)
  date.setMinutes(+hours * 60)
  const time = `${date.getHours()}h`

  return date.getMinutes() > 0 ? `${time}${date.getMinutes()}` : time
}

const getTimeslotTimeZone = (timeSlotRange, date) =>
  `T${timeSlotRange} +0${(date.getTimezoneOffset() / 60) * -1}:00`

export const getFormatedDateTimeslot = (dateString, timeslot) => {
  const timeSlotRange = timeslot.split('-')
  const date = new Date(dateString)
  const dateStringFormatted = dateString.substring(0, 10)

  return {
    startTime: `${dateStringFormatted}${getTimeslotTimeZone(
      timeSlotRange[0],
      date,
    )}`,
    stopTime: `${dateStringFormatted}${getTimeslotTimeZone(
      timeSlotRange[1],
      date,
    )}`,
  }
}

export const formatDatesHo = values => {
  const dates = []
  const timeslots = {
    morning: '08:00:00.000-13:30:00.000',
    afternoon: '13:30:00.000-18:00:00.000',
  }

  values.timeslots.forEach(({ date, timeslot }) => {
    date =
      typeof date === 'string' ? date : format(date, DATE_FORMAT.ISO_TIMEZONE)

    switch (timeslot) {
      case 'morning':
      case 'afternoon':
        dates.push(getFormatedDateTimeslot(date, timeslots[timeslot]))
        break
      default:
        Object.keys(timeslots).forEach(key => {
          dates.push(getFormatedDateTimeslot(date, timeslots[key]))
        })
        break
    }
  })

  return dates
}

export const isNotSunday = date => getDay(date) !== 0

export const getHolidays = (country, year) => {
  const hd = new Holidays(country)

  return hd.getHolidays(year)
}

export const getExcludedDates = maxDeliveryDate => {
  const today = new Date()
  const holidays = getHolidays('FR', today.getFullYear()).map(holiday =>
    holiday.type === 'public' ? holiday.start : '',
  )

  // const dateStart =
  //   maxDeliveryDate && maxDeliveryDate.getTime() > today.getTime()
  //     ? maxDeliveryDate
  //     : today
  // const dateRange = addDays(new Date(dateStart), 2)
  // const range = getDaysRange(today, dateRange)

  return [...holidays /* , ...range */]
}

export const getDaysRange = (startDate, stopDate) => {
  let dates = []
  // to avoid modifying the original date
  const date = new Date(startDate)
  while (date < stopDate) {
    dates = [...dates, new Date(date)]
    date.setDate(date.getDate() + 1)
  }

  return dates
}

export const addDays = (date, days) => {
  date.setDate(date.getDate() + days)

  return date
}

export const extractDateFromString = date => {
  let day
  let month
  let year
  let dateSplitted
  let aux

  let result = date.match('[0-9]{2}([-/ .])[0-9]{2}[-/ .][0-9]{4}')
  if (result !== null) {
    dateSplitted = result[0].split(result[1])
    day = dateSplitted[0]
    month = dateSplitted[1]
    year = dateSplitted[2]
  }

  result = date.match('[0-9]{4}([-/ .])[0-9]{2}[-/ .][0-9]{2}')
  if (result !== null) {
    dateSplitted = result[0].split(result[1])
    day = dateSplitted[2]
    month = dateSplitted[1]
    year = dateSplitted[0]
  }

  if (month > 12) {
    aux = day
    day = month
    month = aux
  }

  return `${day}/${month}/${year}`
}

export const getMaxDate = dates =>
  new Date(Math.max(...dates.map(element => new Date(element))))

export const getNextWorkDay = date => {
  const today = new Date()
  let nextWorkDay = date
  nextWorkDay.setHours(0)
  nextWorkDay.setMinutes(0)
  nextWorkDay.setSeconds(0)

  if (!isNotSunday(nextWorkDay)) {
    nextWorkDay = addDays(nextWorkDay, 1)
  }

  const holidays = getHolidays('FR', today.getFullYear()).map(holiday =>
    holiday.type === 'public' ? holiday.start.getTime() : '',
  )

  return !holidays.includes(nextWorkDay.getTime())
    ? nextWorkDay
    : getNextWorkDay(addDays(nextWorkDay, 1))
}

export const isMondayOrTuesday = date => {
  const weekDay = date.toString().substring(0, 3)
  return weekDay === 'Mon' || weekDay === 'Tue'
}

import axios from 'axios'
import { billing } from 'config'
import { interceptor } from 'initializers/initializeAxios'

const client = axios.create({
  baseURL: billing.url,
})

client.interceptors.request.use(interceptor)

export default client

import {
  ADD_NOTIFICATIONS,
  OPEN_NOTIFICATIONS,
  REMOVE_NOTIFICATIONS,
} from './actions'

export default (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATIONS:
      return [
        ...state,
        {
          id: action.id,
          status: action.status,
          message: action.message,
          title: action.title,
          autoClose: action.autoClose,
          opened: false,
        },
      ]
    case OPEN_NOTIFICATIONS: {
      const updateNotifications = state.map(notification => {
        if (notification.id === action.id) {
          return {
            ...notification,
            toastId: action.toastId,
            opened: true,
          }
        }

        return notification
      })

      return updateNotifications
    }

    case REMOVE_NOTIFICATIONS:
      return []
    default: {
      return state
    }
  }
}

const fr = require('./fr')

const l11n = {
    fr,
}

module.exports = {
    default: l11n,
    fr,
}

import cookie from 'react-cookie'

const SPN = 'spn'

export const getSpnFromCookie = () => cookie.load(SPN)

export const saveSpnInCookie = spn => {
  const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 30)
  cookie.save(SPN, spn, { path: '/', expires })
}

/**
 * Convert an array like: [
 *    { code: 'QUOTATIS', name: 'quotatis'},
 *    { code: 'LEROY_MERLIN', name: 'Leroy merlin'},
 *    ]
 * into an object like:
 *    {
 *      QUOTATIS: {code: 'QUOTATIS', name: 'quotatis'},
 *      LEROY_MERLIN: { code: 'LEROY_MERLIN', name: 'Leroy merlin'},
 *    }
 * @param  {Array} list
 * @param  {String} [uniqueKey='code'] This is the key in your array that should be unique
 * @return {Object}
 */
export const convertArrayToObject = (list, uniqueKey = 'code') =>
  list.reduce(
    (element, current) => ({ ...element, [current[uniqueKey]]: current }),
    {},
  )

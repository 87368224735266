import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { mocking, google, trackingLocales } from 'config'
import { fromContext, fromRouting } from 'store/selectors'

import GoogleTagManagerView from './View'

const GoogleTagManager = ({ jobId = undefined, country, locale, baseUrl }) => {
  const gtmId =
    trackingLocales[locale].url === baseUrl
      ? google.tagManagerId.poseTracking
      : google.tagManagerId[country]

  if (mocking) {
    window.dataLayer = []
  }

  return (
    <div suppressHydrationWarning>
      {!mocking && (
        <GoogleTagManagerView
          jobId={jobId}
          locale={locale}
          baseUrl={baseUrl}
          gtmId={gtmId}
        />
      )}
    </div>
  )
}

GoogleTagManager.propTypes = {
  country: PropTypes.string.isRequired,
  jobId: PropTypes.string,
}

export default connect(state => ({
  country: fromContext.getCountry(state),
  locale: fromContext.getLocale(state),
  baseUrl: fromRouting.getBaseUrl(state),
}))(GoogleTagManager)

import { mocking } from 'config'

const pushGtmEvent = (item, callback) => {
  const event = { ...item }

  if (callback) {
    event.eventCallback = callback
  }

  if (
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    window.dataLayer.push(event)
  }

  if (mocking && callback) {
    callback()
  }
}

export default pushGtmEvent

import IntlMessageFormat from 'intl-messageformat'
import memoizeIntlConstructor from 'intl-format-cache'
import i18n from '../i18n'

const getMessageFormat = memoizeIntlConstructor(IntlMessageFormat)

export default lang => {
  const messages = i18n[lang]
  if (!messages) {
    throw new Error(
      `Invalid lang ${lang} (only ${Object.keys(i18n).join(
        ', ',
      )} are available)`,
    )
  }
  return (messageID, values) => {
    const messageFormat = getMessageFormat(messages[messageID], lang, {})
    return messageFormat.format(values)
  }
}

import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'
import { reducer as featureFlagsReducers } from 'feature-flag-consumer-js-lib'
import auth from './auth/reducer'
import context from './context/reducer'
import firm from './firm/reducer'
import job from './job/reducer'
import notifications from './notifications/reducer'
import routing from './routing/reducer'
import pro from './pro/reducer'
import status from './status/reducer'

export default history =>
  combineReducers({
    router: connectRouter(history),
    featureFlags: featureFlagsReducers,
    // form: formReducer.plugin(proDocuments),
    form: formReducer,
    job,
    routing,
    firm,
    pro,
    status,
    context,
    auth,
    notifications,
  })

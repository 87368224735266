export const URLS = {
  QUOTATIS: {
    APP_METADATA: 'http://quotatis.fr/app_metadata',
    USER_AUTHORIZATION: 'http://quotatis.fr/user_authorization',
  },
  ROUTES: {
    PRO_LOGIN: '/pro/login',
    PRO_ACCOUNT: '/pro/my-account/page',
    CANDIDATE_PACKAGE: '/pro/my-account/candidate-packages',
    CANDIDATE_PACKAGE_DOCS: '/pro/my-account/candidate-packages/documents',
    CANDIDATE_PACKAGE_STUDY: '/pro/my-account/candidate-packages/study',
    CANDIDATE_PACKAGE_CONFIGURATION:
      '/pro/my-account/candidate-packages/configuration',
    CANDIDATE_QUOTATION: '/pro/my-account/candidate-quotations',
    CANDIDATE_QUOTATION_DOCS: '/pro/my-account/candidate-quotations/documents',
    CANDIDATE_QUOTATION_STUDY: '/pro/my-account/candidate-quotations/study',
    CANDIDATE_QUOTATION_CONFIGURATION:
      '/pro/my-account/candidate-quotations/configuration',
    REGISTER_QUOTATION: '/pro/inscription-releve-technique',
  },
}

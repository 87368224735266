export const EMPLOYEES_NB_CHOICES = {
  FR: {
    ALONE: 'firm.details.employees_number.alone',
    LES_5: 'firm.details.employees_number.les_5',
    BET_6_15: 'firm.details.employees_number.bet_5_15',
    BET_16_50: 'firm.details.employees_number.bet_16_50',
    MOR_50: 'firm.details.employees_number.mor_50',
  },
  ES: {
    ALONE: 'firm.details.employees_number.alone',
    LES_5: 'firm.details.employees_number.les_5',
    BET_6_15: 'firm.details.employees_number.bet_5_15',
    BET_16_50: 'firm.details.employees_number.bet_16_50',
    MOR_50: 'firm.details.employees_number.mor_50',
  },
}

export const POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG = 'firmCoverEditPopinDisplayed'
export const POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG = 'firmLogoEditPopinDisplayed'
export const PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG = 'proMyAccountNav2Displayed'
export const DEFAULT_FIRM_LOGO =
  'https://res.cloudinary.com/quotatis/image/upload/FrontApp/icons/default-logo-profil.svg'

export const SINGLE_TYPE_FIRM = 1
export const COMPANY_TYPE_FIRM = 2

export const SIGNUP = 'signup'
export const PRO_DIRECTORY = 'proDirectory'
export const CANDIDATE_PACKAGES = 'candidatePackages'

export const TOKEN = {
  STATUS: {
    ALREADY_USED: 'firm.validate_email.token_already_used',
    EXPIRED: 'firm.validate_email.token_expired',
    ERROR: 'firm.validate_email.error',
    SUCCESS: 'firm.validate_email.success',
  },
}

export const CONTRACT_SIGNATURE_ID = 'contractSignatureId'
export const CONTRACT_SIGNATURE_STATUS = {
  STATUS: { NULL: 'null', FINISHED: 'finished' },
}
export const CONTRACT_SIGNATURE_AT = 'contractSignedAt'
export const FIRM_FILE = 'FirmFile'

export const PACKAGE_STATUS_NOT_CANDIDATE = 'pending_test_job'
export const PACKAGE_STATUS_CANDIDATE = 'candidate'
export const PACKAGE_STATUS_PENDING_TEST_JOB = 'pending_test_job'
export const PACKAGE_STATUS_IN_PROGRESS_TEST_JOB = 'in_progress_test_job'
export const PACKAGE_STATUS_VALIDATED = 'validated'
export const PACKAGE_STATUS_SUSPENDED_DEFINITIVELY = 'suspended_definitively'
export const PACKAGE_STATUS_SUSPENDED_TEMPORARY = 'suspended_temporary'
export const QUOTATION_STATUS_CANDIDATE = 'candidate'

export const FIRM_CONTRACT_OPTIONS = [
  { code: 'PACKAGE', name: 'Package' },
  { code: 'PICK', name: 'Select' },
  { code: 'PUSH', name: 'Connect' },
  { code: 'DIRECTORY', name: 'Directory' },
  { code: 'NO_CONTRACT', name: 'Sans contrat' },
  { code: 'QUOTATION', name: 'Quotation' },
]

export const ADD_USER = 'ADD_USER'
export const DELETE_USER = 'DELETE_USER'
export const UPDATE_NEWSLETTER_OPTINS = 'UPDATE_NEWSLETTER_OPTINS'

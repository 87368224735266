import { put, select } from 'redux-saga/effects'
import notify from 'sagas/notify'
import { authLogout } from 'store/auth/actions'
import { fromAuth } from 'store/selectors'
import * as Sentry from '@sentry/react'

export default function* handleRequest({
  requestActions,
  promise,
  actionParams,
  checkTokens = true,
}) {
  try {
    if (checkTokens) {
      const idTokenExpAt = yield select(fromAuth.selectIdTokenExpAt)
      const now = new Date().getTime() / 1000

      if (idTokenExpAt !== null && now >= idTokenExpAt) {
        yield put(authLogout(false))
        yield* notify('', 'user.sign_in.token_expired', 'error')
        throw new Error('Token expired before call api begin')
      }
    }

    const response = yield promise
    yield put(requestActions.success(response.data, actionParams))

    return response
  } catch (e) {
    if (checkTokens && e?.response?.data?.detail === 'Expired token') {
      Sentry.captureException(e)
      yield put(authLogout(false))
      yield* notify('', 'user.sign_in.token_expired', 'error')
    }

    yield put(requestActions.failure(e, actionParams))

    throw e
  }
}

import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import initializeLocale from 'initializers/initializeLocale'
import initializeStyle from 'initializers/initializeStyle'
import { ThemeProvider } from 'styled-components'
import { clientInitiated } from 'store/actions'
import IntlProvider from './components/IntlProvider'
import GoogleTagManager from 'components/GoogleTagManager'
import defaultTheme from './theme/default'
import sagas from 'store/sagas'
import { history } from './store/store'
import createRoutes from './routes'

// Polyfill
// https://www.npmjs.com/package/intl
if (!global.Intl) require('intl')

const App = ({ store }) => {
  const [routes, setRoutes] = useState([])

  useEffect(() => {
    initializeLocale()
    initializeStyle()

    store.runSaga(sagas)
    store.dispatch(clientInitiated())

    const { lang } = store.getState().context
    setRoutes(createRoutes(lang, window.location.origin))
  }, [store])

  return (
    <IntlProvider>
      <ThemeProvider theme={defaultTheme}>
        <ConnectedRouter history={history}>
          <GoogleTagManager />
          <Switch>
            {(routes || []).map(route => (
              <Route key={route.path} {...route} />
            ))}
          </Switch>
        </ConnectedRouter>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default App

export const signUpErrors = {
  invalid_password: {
    propertyPath: 'password',
    message: 'validators.user.password.invalid_password',
  },
  password_dictionary_error: {
    propertyPath: 'password',
    message: 'validators.password.dictionary_error',
  },
  password_strength_error: {
    propertyPath: 'password',
    message: 'validators.password.strength_error',
  },
  user_exists: {
    propertyPath: 'email',
    message: 'validators.user.already_exist',
  },
}

export const signInErrors = {
  invalid_grant: {
    error_description: 'Invalid username and password combination',
  },
  too_many_attempts: {
    error_description: 'user.sign_in.blocked_many_attempts',
  },
  unauthorized: {
    error_description: 'user.sign_in.unauthorized',
  },
}

import axios from 'axios'
import { proApi } from 'config'
import { interceptor } from 'initializers/initializeAxios'

const client = axios.create({
  baseURL: proApi.url,
  headers: {
    'Content-Type': 'application/ld+json',
  },
})

client.interceptors.request.use(interceptor)

export default client

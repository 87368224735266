import {
  POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG,
  POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG,
  PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG,
} from 'constants/firm'
import { POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG } from 'constants/job'
import {
  CONTEXT_SET_COUNTRY,
  CONTEXT_SET_LANG,
  CONTEXT_TOGGLE_MAIN_NAVIGATION,
  CONTEXT_TOGGLE_ACCOUNT_NAVIGATION,
  CONTEXT_TOGGLE_SIGN_IN_POPIN,
  CONTEXT_TOGGLE_SIGN_IN_HO_POPIN,
  CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN,
  CONTEXT_TOGGLE_SIGN_IN_PRO_POPIN,
  CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN_MODE,
  CONTEXT_TOGGLE_EMAIL_VALIDATION_POPIN,
  CONTEXT_SET_PHONE_VALIDATION_POPIN_MODE,
  CONTEXT_SET_PATH_NAME_CONTEXT,
  CONTEXT_SET_PATH_NAME_ORIGIN,
  CONTEXT_TOGGLE_PHONE_VALIDATION_POPIN,
  CONTEXT_CLOSE_ALL,
  CONTEXT_SET_INITIAL_QUERY_PARAMS,
  CONTEXT_REMOVE_INITIAL_QUERY_PARAM,
  CONTEXT_SET_PARTNER,
  CONTEXT_TOGGLE_SEARCH_MODAL,
  CONTEXT_SHOW_COOKIES_BANNER,
  CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN,
  CONTEXT_TOGGLE_POPIN_DISPLAY,
} from './actions'
import { initialState } from './selectors'

export default (state = initialState, action) => {
  switch (action.type) {
    case CONTEXT_SET_COUNTRY:
      return {
        ...state,
        country: action.payload,
      }
    case CONTEXT_SET_LANG:
      return {
        ...state,
        lang: action.payload,
      }
    case CONTEXT_TOGGLE_MAIN_NAVIGATION: {
      return {
        ...state,
        mainNavigation:
          action.payload != null ? action.payload : !state.mainNavigation,
      }
    }
    case CONTEXT_TOGGLE_ACCOUNT_NAVIGATION: {
      return {
        ...state,
        accountNavigation:
          action.payload != null ? action.payload : !state.accountNavigation,
      }
    }
    case CONTEXT_TOGGLE_SIGN_IN_POPIN: {
      return {
        ...state,
        signInPopin:
          action.payload != null ? action.payload : !state.signInPopin,
      }
    }
    case CONTEXT_TOGGLE_SIGN_IN_HO_POPIN: {
      return {
        ...state,
        signInSignUpHoPopin: {
          mode: 'signin',
          enabled: !state.signInSignUpHoPopin.enabled,
        },
      }
    }
    case CONTEXT_TOGGLE_SIGN_IN_PRO_POPIN: {
      return {
        ...state,
        signInProPopin: !state.signInProPopin,
      }
    }
    case CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN: {
      return {
        ...state,
        signInSignUpHoPopin: {
          ...state.signInSignUpHoPopin,
          enabled:
            action.payload != null
              ? action.payload
              : !state.signInSignUpHoPopin.enabled,
        },
      }
    }
    case CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN_MODE: {
      return {
        ...state,
        signInSignUpHoPopin: {
          ...state.signInSignUpHoPopin,
          mode:
            state.signInSignUpHoPopin.mode === 'signin' ? 'signup' : 'signin',
        },
      }
    }
    case CONTEXT_TOGGLE_PHONE_VALIDATION_POPIN: {
      return {
        ...state,
        phoneValidationPopin: {
          ...state.phoneValidationPopin,
          enabled:
            action.payload != null
              ? action.payload
              : !state.phoneValidationPopin.enabled,
        },
      }
    }
    case CONTEXT_SET_PHONE_VALIDATION_POPIN_MODE: {
      return {
        ...state,
        phoneValidationPopin: {
          ...state.phoneValidationPopin,
          mode: action.payload,
        },
      }
    }
    case CONTEXT_SET_PATH_NAME_CONTEXT: {
      return {
        ...state,
        pathContext: action.payload,
      }
    }
    case CONTEXT_SET_PATH_NAME_ORIGIN: {
      return {
        ...state,
        pathNameOrigin: action.payload,
      }
    }
    case CONTEXT_TOGGLE_EMAIL_VALIDATION_POPIN: {
      return {
        ...state,
        emailValidationPopin:
          action.payload != null ? action.payload : !state.emailValidationPopin,
      }
    }
    case CONTEXT_CLOSE_ALL: {
      return {
        ...state,
        mainNavigation: false,
        accountNavigation: false,
        signInPopin: false,
        signInHoPopin: false,
        signInProPopin: false,
        signInSignUpHoPopin: {
          ...state.signInSignUpHoPopin,
          enabled: false,
        },
        phoneValidationPopin: {
          ...state.phoneValidationPopin,
          enabled: false,
        },
        emailValidationPopin: false,
        firmPicturesEditPopinDisplayed: false,
        [POP_IN_FIRM_COVER_EDIT_DISPLAY_FLAG]: false,
        [POP_IN_FIRM_LOGO_EDIT_DISPLAY_FLAG]: false,
        [PRO_MY_ACCOUNT_NAV2_DISPLAY_FLAG]: false,
        [POP_IN_PRO_JOB_DECLINE_REASON_DISPLAY_FLAG]: false,
      }
    }
    case CONTEXT_SET_INITIAL_QUERY_PARAMS: {
      return {
        ...state,
        initialQueryParams: { ...action.payload },
      }
    }
    case CONTEXT_REMOVE_INITIAL_QUERY_PARAM: {
      return {
        ...state,
        initialQueryParams: {
          ...state.initialQueryParams,
          [action.payload]: null,
        },
      }
    }
    case CONTEXT_SET_PARTNER: {
      return {
        ...state,
        partner: action.payload,
      }
    }
    case CONTEXT_TOGGLE_SEARCH_MODAL: {
      return {
        ...state,
        searchModalIsOpen: action.payload,
      }
    }
    case CONTEXT_SHOW_COOKIES_BANNER: {
      return {
        ...state,
        cookiesBannerIsOpen: action.payload,
      }
    }
    case CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN:
      return {
        ...state,
        firmPicturesEditPopinDisplayed: !state.firmPicturesEditPopinDisplayed,
      }
    case CONTEXT_TOGGLE_POPIN_DISPLAY:
      return {
        ...state,
        [action.payload]: !state[action.payload],
      }
    default: {
      return state
    }
  }
}

import { put, select, all } from 'redux-saga/effects'
import { stopSubmit, reset } from 'redux-form'
import { push } from 'react-router-redux'
import notify from 'sagas/notify'
import { saveTokens, removeTokens, bootstrapAuthFromLs } from 'utils/token'
import handleRequest from 'sagas/handleRequest'
import { takeLatest } from 'utils/effects'
import pushGtmEvent from 'utils/gtm'
import { getInternationalPhoneNumber } from 'utils/phone/phone'
import auth from 'services/auth'
import authTracking from 'services/authTracking'
import {
  authLogin,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  HO_AUTH_LOGIN_SOCIAL_NETWORK,
  closeAll,
  setRedirectPathname,
  CLIENT_INITIATED,
  authPasswordlessSmsStart,
  AUTH_PASSWORDLESS_SMS_START,
  authPasswordlessSmsLogin,
  AUTH_PASSWORDLESS_SMS_LOGIN,
  CHECK_HASH_TOKEN,
  NO_JOB_REDIRECT,
  SET_AUTH_TOKENS,
  setCheckSessionStatus,
} from '../actions'
import qs from 'query-string'
import { fromRouting, fromContext } from 'store/selectors'
import {
  ACCESS_TOKEN,
  ID_TOKEN,
  SESSION_STATUS_DEFAULT,
  SESSION_STATUS_SUCCESS,
} from 'constants/auth'

export function* handleAuthLoginRequest({
  formName,
  username,
  password,
  accessToken,
  grantType,
}) {
  try {
    const response = yield* handleRequest({
      requestActions: authLogin,
      promise: auth.login({
        username,
        password,
        accessToken,
        grantType,
        realm: 'home-owner',
      }),
      checkTokens: false,
    })

    yield saveTokens({
      [ACCESS_TOKEN]: response[ACCESS_TOKEN],
      [ID_TOKEN]: response[ID_TOKEN],
    })

    yield put(reset(formName))
  } catch (e) {
    // TODO add check for violations
    yield put(stopSubmit(formName, { _error: e.error_description }))
    throw e
  }
}

function* handleAuthSocialNetworkLogin(action) {
  try {
    const lang = yield select(fromContext.getLang)
    const country = yield select(fromContext.getCountry)
    const response = yield* handleRequest({
      requestActions: authLogin,
      promise: auth.loginSocialNetwork(action.payload, `${lang}-${country}`),
      checkTokens: false,
    })

    yield saveTokens({
      [ACCESS_TOKEN]: response[ACCESS_TOKEN],
      [ID_TOKEN]: response[ID_TOKEN],
    })
  } catch (e) {
    if (!e.code) {
      return
    }

    throw e
  }
}

function* handleAuthLogout({ payload = true }) {
  yield put(closeAll())
  removeTokens()
  yield put(setCheckSessionStatus(SESSION_STATUS_DEFAULT))

  if (!payload) {
    return
  }

  const pathName = yield select(fromRouting.getPathname)

  if (pathName !== '/') {
    yield put(push('/'))
  }
}

function* handleAuthLoginSuccess() {
  yield put(closeAll())
  yield* notify('', 'user.sign_in.success')

  const redirectPathname = yield select(fromRouting.getRedirectPathname)

  if (redirectPathname) {
    yield pushGtmEvent({ event: 'LoginOk', email })
    yield put(push(redirectPathname))
    yield put(setRedirectPathname(null))
  }
}

function handleAuthLoginFailed() {
  removeTokens()
}

function* handleClientInitiated() {
  yield bootstrapAuthFromLs()
}

function* handlePasswordlessSmsStartRequest({ phoneNumber }) {
  yield* handleRequest({
    requestActions: authPasswordlessSmsStart,
    promise: authTracking.authPasswordlessSmsStart(
      getInternationalPhoneNumber(phoneNumber),
    ),
    checkTokens: false,
  })

  yield put(reset('PasswordlessCodeForm'))
}

function* handlePasswordlessSmsLoginRequest({ code, phoneNumber }) {
  const redirectPathname = yield select(fromRouting.getRedirectPathname)
  yield* handleRequest({
    requestActions: authPasswordlessSmsLogin,
    promise: authTracking.authPasswordlessSmsLogin(
      code,
      getInternationalPhoneNumber(phoneNumber),
      redirectPathname,
      yield select(fromContext.getPartner),
    ),
    checkTokens: false,
  })
}

function* handleCheckHashToken() {
  const authResult = yield authTracking.parseHashFromPasswordless()

  if (authResult !== null) {
    yield saveTokens({
      [ACCESS_TOKEN]: authResult[ACCESS_TOKEN],
      [ID_TOKEN]: authResult[ID_TOKEN],
    })
    yield put(setCheckSessionStatus(SESSION_STATUS_SUCCESS))

    const queryParams = qs.parse(window.location.search)
    const trackingPage = queryParams.page

    if (trackingPage) {
      yield put(push(trackingPage))
    }
  } else {
    const redirectPathname = yield select(fromRouting.getRedirectPathname)

    if (!redirectPathname) {
      yield handleNoJobRedirect()
    }
  }
}

function* handleNoJobRedirect() {
  yield put(push('/'))
}

function handleSetAuthTokens({ payload: { idToken, accessToken } }) {
  if (idToken && accessToken) {
    localStorage.setItem(ID_TOKEN, idToken)
    localStorage.setItem(ACCESS_TOKEN, accessToken)
  }
}

export default function* () {
  yield all([
    takeLatest(CLIENT_INITIATED, handleClientInitiated),
    takeLatest(AUTH_LOGIN.REQUEST, handleAuthLoginRequest),
    takeLatest(AUTH_LOGIN.SUCCESS, handleAuthLoginSuccess),
    takeLatest(AUTH_LOGIN.FAILURE, handleAuthLoginFailed),
    takeLatest(AUTH_LOGOUT, handleAuthLogout),
    takeLatest(HO_AUTH_LOGIN_SOCIAL_NETWORK, handleAuthSocialNetworkLogin),
    takeLatest(
      AUTH_PASSWORDLESS_SMS_START.REQUEST,
      handlePasswordlessSmsStartRequest,
    ),
    takeLatest(
      AUTH_PASSWORDLESS_SMS_LOGIN.REQUEST,
      handlePasswordlessSmsLoginRequest,
    ),
    takeLatest(CHECK_HASH_TOKEN, handleCheckHashToken),
    takeLatest(NO_JOB_REDIRECT, handleNoJobRedirect),
    takeLatest(SET_AUTH_TOKENS, handleSetAuthTokens),
  ])
}

import set from 'lodash/set'
import { routerReducer, LOCATION_CHANGE } from 'react-router-redux'
import { initialState } from './selectors'
import { ROUTING_SET_REDIRECT_PATHNAME } from './actions'

export default (state = initialState, action) => {
  switch (action.type) {
    case ROUTING_SET_REDIRECT_PATHNAME: {
      return set(
        state,
        ['location', 'state', 'redirectPathname'],
        action.payload,
      )
    }
    case LOCATION_CHANGE: {
      return routerReducer(
        {
          ...state,
          referrer: state.location,
          baseUrl: window.location.origin,
        },
        action,
      )
    }
    default: {
      return routerReducer(
        {
          ...state,
          baseUrl: window.location.origin,
        },
        action,
      )
    }
  }
}

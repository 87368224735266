import get from 'lodash/get'
import { convertArrayToObject } from 'utils/convert'
// import { contractOptionDirectory } from 'constants/contractOptions'
import {
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB,
  PACKAGE_STATUS_PENDING_TEST_JOB,
  FIRM_CONTRACT_OPTIONS,
  PACKAGE_STATUS_CANDIDATE,
  QUOTATION_STATUS_CANDIDATE,
} from 'constants/firm'
import { NO_SCORE } from 'constants/onboarding'
import { FIRM_STATUS_CAN_UPDATE_DOCUMENTS } from 'constants/documents'

export const initialState = {
  reviews: {},
  hashids: {},
  autoCompleteLocation: {},
  currentFirmId: null,
  firmValidationEmailStatus: null,
  firmFiles: {},
}

const firmContractOptions = convertArrayToObject(FIRM_CONTRACT_OPTIONS)

export const getDetails = (state, id) => state[id]
export const getFirmLegalData = (state, id) =>
  get(getDetails(state, id), 'firmLegalData')
export const getFirmFiles = (state, id) =>
  get(getDetails(state, id), 'firmFiles')
export const getCurrentFirm = state => state.currentFirmId
export const getId = (state, hashid) => state.hashids[hashid]
export const getReviews = (state, firmId) => state.reviews[`${firmId}/reviews`]
export const getDetailsFromHashId = (state, hashId) =>
  getDetails(state, getId(state, hashId))
export const getFirmPlaceAutoCompleteLocation = state =>
  state.autoCompleteLocation
export const getIsLoginRequest = state => state.isLoginRequest
export const getFirmContractOption = (state, id) =>
  getDetails(state, id).contractOptions
export const getFirmContractOptionList = (state, id) => {
  const contractOptions = getFirmContractOption(state, id) || []
  return contractOptions && contractOptions.length > 0
    ? contractOptions
        .map(option => firmContractOptions[option.name].name)
        .join(' / ')
    : firmContractOptions.NO_CONTRACT.name
}
export const canFirmUpdateDocuments = (state, id) => {
  const hasStatusToUpdate = (FIRM_STATUS_CAN_UPDATE_DOCUMENTS || []).find(
    status => status === getPackageStatusV2(state, id),
  )

  return !!hasStatusToUpdate
}

// export const getHasContractOption = (state, id) =>
//   (getFirmContractOption(state, id) || []).some(
//     contract => contract.name === contractOptionDirectory,
//   )
export const getAccountingNumber = (state, id) =>
  getDetails(state, id).accountingNumber

export const getFirmTradesOfPro = state => get(state, 'firmOfPro.firmTrades')
export const getGlobalRating = state => get(state, 'firmOfPro.globalRating')
export const getGlobalRatingCount = state =>
  get(state, 'firmOfPro.globalRatingCount')
export const getAcceptedProProfilePath = state => get(state, 'firmOfPro.url')
export const getAcceptedProEmail = state => get(state, 'firmOfPro.email')

export const getFirmUserByEmail = (state, id, email) =>
  getDetails(state, id).users.find(user => user.email === email)
export const getOwnerPosition = (state, id, emailAuth) =>
  getDetails(state, id).users.indexOf(
    getDetails(state, id).users.find(user => user.email === emailAuth),
  ) || 0

export const getFirmGenderOwner = (state, id, emailAuth) => {
  const authGender = get(getFirmUserByEmail(state, id, emailAuth), 'gender')

  return authGender || getDetails(state, id).users[0].gender
}
export const getFirmFirstNameOwner = (state, id, emailAuth) => {
  const authFirstName = get(
    getFirmUserByEmail(state, id, emailAuth),
    'firstName',
  )

  return authFirstName || getDetails(state, id).users[0].firstName
}
export const getFirmLastNameOwner = (state, id, emailAuth) => {
  const authLastName = get(getFirmUserByEmail(state, id, emailAuth), 'lastName')

  return authLastName || getDetails(state, id).users[0].lastName
}
export const getFirmEmailOwner = (state, id, emailAuth) => {
  const authEmail = get(getFirmUserByEmail(state, id, emailAuth), 'email')

  return authEmail || getDetails(state, id).users[0].email
}
export const getFirmFixedPhoneOwner = (state, id, emailAuth) => {
  const authFixedPhone = get(
    getFirmUserByEmail(state, id, emailAuth),
    'fixedPhone',
  )

  return authFixedPhone || getDetails(state, id).users[0].fixedPhone
}
export const getFirmMobilePhoneOwner = (state, id, emailAuth) => {
  const authMobilePhone = get(
    getFirmUserByEmail(state, id, emailAuth),
    'mobilePhone',
  )

  return authMobilePhone || getDetails(state, id).users[0].mobilePhone
}
export const getFirmFirstName = (state, id, userNumber) =>
  getDetails(state, id).users[userNumber].firstName
export const getFirmLastName = (state, id, userNumber) =>
  getDetails(state, id).users[userNumber].lastName
export const getFirmEmail = (state, id, userNumber) =>
  getDetails(state, id).users[userNumber].email
export const getFixedPhone = (state, id, userNumber) =>
  getDetails(state, id).users[userNumber].fixedPhone
export const getMobilePhone = (state, id, userNumber) =>
  getDetails(state, id).users[userNumber].mobilePhone
export const getFirmTrades = (state, id) => getDetails(state, id).firmTrades
export const getIsEmailValidated = (state = initialState, id) =>
  getDetails(state, id).isEmailValidated
export const getFirmName = (state, id) =>
  getDetails(state, id) && getDetails(state, id).name
export const getFirmValidationEmailStatus = state =>
  state.firmValidationEmailStatus
export const getContractSignatureStatus = (
  state = initialState,
  id,
  override,
) => get(getDetails(state, id), override ?? 'contractSignatureStatus')
export const getIsContractSigned = (state, id, type = '') =>
  getContractSignatureStatus(
    state,
    id,
    type ? `${type}ContractSignatureStatus` : undefined,
  ) === 'finished'
export const getCandidatePackageValidationError = state =>
  state.candidatePackageValidationError
export const getIsFirmConfigured = (state, id) =>
  getIBAN(state, id) && getSwiftCode(state, id) && getVAT(state, id)
export const getIBAN = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.internationalBankAccountNumber')
export const getVAT = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.vatNumber')
export const getSwiftCode = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.swiftCode')
export const getHeadQuartersAddress = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.headQuartersAddress')
export const getHeadQuartersPostalCode = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.headQuartersPostalCode')
export const getHeadQuartersCity = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.headQuartersCity')
export const getLegalRepresentativeFirstName = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.legalRepresentativeFirstName')
export const getLegalRepresentativeLastName = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.legalRepresentativeLastName')
export const getRegistrationNumber = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.registrationNumber')
export const getLegalStatus = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.legalStatus')
export const getFirmType = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.firmType')
export const getCapitalAmount = (state, id) => {
  const capitalAmount = get(
    getDetails(state, id),
    'firmLegalData.capitalAmount',
  )

  return capitalAmount ? capitalAmount.toString() : undefined
}
export const getRegistrationCity = (state, id) =>
  get(getDetails(state, id), 'firmLegalData.registrationCity')
export const getNewsletterPartnerOptin = (state, id) =>
  get(getDetails(state, id), 'newsletterSubscriptionPartners')
export const getNewsletterQuotatisOptin = (state, id) =>
  get(getDetails(state, id), 'newsletterSubscriptionQuotatis')
export const getPackageStatus = (state, id) =>
  get(getDetails(state, id), 'packageStatus')
export const getPackageStatusV2 = (state, id) =>
  get(getDetails(state, id), 'packageStatusV2')
export const isFirmInProgressTestJobSelector = (state, id) =>
  PACKAGE_STATUS_IN_PROGRESS_TEST_JOB === getPackageStatusV2(state, id)
export const isFirmPendingTestJobSelector = (state, id) =>
  PACKAGE_STATUS_PENDING_TEST_JOB === getPackageStatusV2(state, id)

export const getFirmFileDetails = (state, id) => state.firmFiles[id]
export const getFirmFilesByType = (state, id, type) =>
  getDetails(state, id)
    .firmFiles.map(
      firmFileId => getFirmFileDetails(state, firmFileId) || firmFileId,
    )
    .filter(firmFile => firmFile.type === type)

export const getDisplayedInProDirectory = (state = initialState, id) =>
  getDetails(state, id).displayedInProDirectory
export const getOnboardingScore = (state = initialState, id) =>
  get(getDetails(state, id), 'onboardingScore')
export const getMandatoryDocumentsStatus = (state = initialState, id) =>
  get(getDetails(state, id), 'mandatoryDocumentsStatus')
export const getIsQuizzValidated = (state, id) =>
  NO_SCORE !== getOnboardingScore(state, id)
export const getProductSubscription = (state, id) =>
  get(getDetails(state, id), 'firmProductSubscription["productSubscriptions"]')
export const getFirmPackageSubscriptionId = (state, id) =>
  get(getDetails(state, id), 'firmPackageSubscription["@id"]')
export const getFirmProductSubscriptionId = (state, id) =>
  get(getDetails(state, id), 'firmProductSubscription["@id"]')
export const getFirmProductSubscriptionPostcodes = (state, id) =>
  get(getDetails(state, id), 'firmProductSubscription["postcodes"]')
export const getFirmLegalDataId = (state, id) =>
  get(getDetails(state, id), 'firmLegalData["@id"]')

export const countDocumentByType = (state, id, type) =>
  get(getDetails(state, id), 'firmFiles').filter(file => file.type === type)
    .length

export const getQuotationStatus = (state, id) =>
  get(getDetails(state, id), 'quotationStatus')

export const isFirmQuotationCandidate = (state, id) =>
  QUOTATION_STATUS_CANDIDATE === getQuotationStatus(state, id)

export const isFirmQuotationCandidateInState = state =>
  QUOTATION_STATUS_CANDIDATE === state.quotationStatus

export const isFirmPackageCandidateInState = state =>
  PACKAGE_STATUS_CANDIDATE === state.packageStatusV2

export const getFirmCertificates = (state, firmId, certificateType) =>
  (state[firmId].firmCertificates || []).find(
    single => single.certificate.type === certificateType,
  )

export const getCategoriesCertificates = (state, firmId, certificateType) => {
  const firmCertificates = getFirmCertificates(state, firmId, certificateType)

  if (!firmCertificates) {
    return null
  }

  const filteredCertificatesCategories = (
    firmCertificates.certificateCategories || []
  ).map(single => single.name)

  return filteredCertificatesCategories
}

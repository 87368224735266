import { generateSimpleAction } from '../../utils/actionHelpers'

export const CONTEXT_SET_LANG = 'CONTEXT_SET_LANG'
export const CONTEXT_SET_COUNTRY = 'CONTEXT_SET_COUNTRY'
export const CONTEXT_TOGGLE_MAIN_NAVIGATION = 'CONTEXT_TOGGLE_MAIN_NAVIGATION'
export const CONTEXT_TOGGLE_ACCOUNT_NAVIGATION =
  'CONTEXT_TOGGLE_ACCOUNT_NAVIGATION'
export const CONTEXT_TOGGLE_SIGN_IN_POPIN = 'CONTEXT_TOGGLE_SIGN_IN_POPIN'
export const CONTEXT_TOGGLE_SIGN_IN_HO_POPIN = 'CONTEXT_TOGGLE_SIGN_IN_HO_POPIN'
export const CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN =
  'CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN'
export const CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN_MODE =
  'CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN_MODE'
export const CONTEXT_TOGGLE_SIGN_IN_PRO_POPIN =
  'CONTEXT_TOGGLE_SIGN_IN_PRO_POPIN'
export const CONTEXT_TOGGLE_PHONE_VALIDATION_POPIN =
  'CONTEXT_TOGGLE_PHONE_VALIDATION_POPIN'
export const CONTEXT_TOGGLE_EMAIL_VALIDATION_POPIN =
  'CONTEXT_TOGGLE_EMAIL_VALIDATION_POPIN'
export const CONTEXT_SET_PHONE_VALIDATION_POPIN_MODE =
  'CONTEXT_SET_PHONE_VALIDATION_POPIN_MODE'
export const CONTEXT_SET_PATH_NAME_CONTEXT = 'CONTEXT_SET_PATH_NAME_CONTEXT'
export const CONTEXT_SET_PATH_NAME_ORIGIN = 'CONTEXT_SET_PATH_NAME_ORIGIN'
export const CONTEXT_CLOSE_ALL = 'CONTEXT_CLOSE_ALL'
export const CONTEXT_SET_INITIAL_QUERY_PARAMS =
  'CONTEXT_SET_INITIAL_QUERY_PARAMS'
export const CONTEXT_REMOVE_INITIAL_QUERY_PARAM =
  'CONTEXT_REMOVE_INITIAL_QUERY_PARAM'
export const CONTEXT_SET_PARTNER = 'CONTEXT_SET_PARTNER'
export const CONTEXT_TOGGLE_SEARCH_MODAL = 'CONTEXT_TOGGLE_SEARCH_MODAL'
export const CONTEXT_SHOW_COOKIES_BANNER = 'CONTEXT_SHOW_COOKIES_BANNER'
export const CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN =
  'CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN'
export const CONTEXT_TOGGLE_POPIN_DISPLAY = 'CONTEXT_TOGGLE_POPIN_DISPLAY'

export const setCountry = generateSimpleAction(CONTEXT_SET_COUNTRY)
export const setLang = generateSimpleAction(CONTEXT_SET_LANG)
export const toggleMainNavigation = generateSimpleAction(
  CONTEXT_TOGGLE_MAIN_NAVIGATION,
  { state: null },
)
export const toggleAccountNavigation = generateSimpleAction(
  CONTEXT_TOGGLE_ACCOUNT_NAVIGATION,
  { state: null },
)
export const toggleSignInPopin = generateSimpleAction(
  CONTEXT_TOGGLE_SIGN_IN_POPIN,
  { state: null },
)
export const toggleSignInHoPopin = generateSimpleAction(
  CONTEXT_TOGGLE_SIGN_IN_HO_POPIN,
  { state: null },
)
export const toggleSignInSignUpHoPopin = generateSimpleAction(
  CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN,
  { state: null },
)
export const toggleSignInSignUpHoPopinMode = generateSimpleAction(
  CONTEXT_TOGGLE_SIGN_IN_SIGN_UP_HO_POPIN_MODE,
)
export const toggleSignInProPopin = generateSimpleAction(
  CONTEXT_TOGGLE_SIGN_IN_PRO_POPIN,
  { state: null },
)
export const toggleSearchModal = generateSimpleAction(
  CONTEXT_TOGGLE_SEARCH_MODAL,
  { state: null },
)
export const showCookiesBanner = generateSimpleAction(
  CONTEXT_SHOW_COOKIES_BANNER,
  { state: null },
)
export const setInitialQueryParams = generateSimpleAction(
  CONTEXT_SET_INITIAL_QUERY_PARAMS,
)
export const setPartner = generateSimpleAction(CONTEXT_SET_PARTNER)
export const removeInitialQueryParam = generateSimpleAction(
  CONTEXT_REMOVE_INITIAL_QUERY_PARAM,
)
export const togglePhoneValidationPopin = generateSimpleAction(
  CONTEXT_TOGGLE_PHONE_VALIDATION_POPIN,
)
export const setPhoneValidationPopinMode = generateSimpleAction(
  CONTEXT_SET_PHONE_VALIDATION_POPIN_MODE,
)
export const setPathNameContext = generateSimpleAction(
  CONTEXT_SET_PATH_NAME_CONTEXT,
)
export const setPathNameOrigin = generateSimpleAction(
  CONTEXT_SET_PATH_NAME_ORIGIN,
)
export const toggleEmailValidationPopin = generateSimpleAction(
  CONTEXT_TOGGLE_EMAIL_VALIDATION_POPIN,
)
export const closeAll = generateSimpleAction(CONTEXT_CLOSE_ALL)
export const toggleFirmPicturesEditPopin = generateSimpleAction(
  CONTEXT_TOGGLE_FIRM_PICTURES_EDIT_POPIN,
)
export const togglePopinDisplay = generateSimpleAction(
  CONTEXT_TOGGLE_POPIN_DISPLAY,
)

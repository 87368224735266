import auth0 from 'auth0-js'
import { getLocaleFromHostname } from 'utils/locale'
import { URLS } from 'constants/urls'
import { ROLES } from 'constants/roles'
import jwtUtils from 'jwt-simple'
import config, { trackingLocales } from '../../config'
import { signUpErrors, signInErrors } from './error'
import { ID_TOKEN } from 'constants/auth'

class Auth0 {
  constructor(clientName, locale) {
    const localeConfig =
      locale && config.locales[locale] ? config.locales[locale].auth0 : {}
    this.auth0conf = { ...config.auth0, ...localeConfig }
    this.clientName = clientName

    this.auth = new auth0.WebAuth({
      domain: this.auth0conf.domain,
      clientID: this.auth0conf[clientName].clientID,
      audience: this.auth0conf[clientName].audience,
      responseType: 'token id_token',
      scope: 'openid offline_access',
    })
  }

  async login({ username, password, realm }) {
    return new Promise((resolve, reject) => {
      this.auth.client.login(
        {
          realm,
          username,
          password,
          grant_type: 'password',
        },
        (err, response) => {
          if (err) {
            reject(err)
            return
          }

          const jwt = jwtUtils.decode(response[ID_TOKEN], null, true)
          if (
            jwt[URLS.QUOTATIS.USER_AUTHORIZATION] &&
            jwt[URLS.QUOTATIS.USER_AUTHORIZATION].roles.indexOf(
              ROLES.ROLE_FIRM_USER,
            ) !== -1
          ) {
            reject({ code: 'unauthorized_firm_user' })
          }

          resolve(response)
        },
      )
    }).catch(error => Promise.reject(signInErrors[error.code]))
  }

  async loginSocialNetwork(socialNetWorkName, lang) {
    return new Promise((resolve, reject) => {
      this.auth.popup.authorize(
        {
          connection: socialNetWorkName,
          redirectUri: 'https://example.com/auth/callback', // Fake redirect url for skip the user
          owp: true,
          scope: 'email openid profile',
          prompt: 'select_account',
          lang,
        },
        (err, response) => {
          if (err) {
            reject(err)
            return
          }

          resolve(response)
        },
      )
    })
  }

  async signUp(user, connection) {
    return new Promise((resolve, reject) => {
      const {
        email,
        password,
        newsletterSubscriptionPartner = false,
        newsletterSubscriptionQuotatis = false,
        ...userInfo
      } = user
      this.auth.signup(
        {
          connection,
          email,
          password,
          user_metadata: {
            newsletterSubscriptionPartner:
              newsletterSubscriptionPartner.toString(), // auth0 accept only string parameters
            newsletterSubscriptionQuotatis:
              newsletterSubscriptionQuotatis.toString(),
            ...userInfo,
          },
        },
        err => {
          if (err) return reject(err)

          return resolve({ data: user })
        },
      )
    }).catch(error =>
      Promise.reject({
        status: error.statusCode,
        violations: [signUpErrors[error.code]],
      }),
    )
  }

  authPasswordlessSmsStart(phoneNumber) {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessStart(
        {
          connection: 'sms',
          send: 'code',
          phoneNumber,
        },
        (err, response) => {
          if (err) {
            reject(err)
            return
          }
          resolve(response)
        },
      )
    }).catch(error => Promise.reject(signInErrors[error.code]))
  }

  authPasswordlessSmsLogin(verificationCode, phoneNumber, trackingPage) {
    return new Promise((resolve, reject) => {
      this.auth.passwordlessLogin(
        {
          connection: 'sms',
          phoneNumber,
          verificationCode,
          redirectUri: `${trackingLocales['fr-FR'].url}/suivi?page=${trackingPage}`,
          scope: 'openid phone',
        },
        (err, response) => {
          if (err) {
            reject(err)
            return
          }
          resolve(response)
        },
      )
    }).catch(error => Promise.reject(signInErrors[error.code]))
  }

  parseHashFromPasswordless() {
    return new Promise(resolve => {
      this.auth.parseHash(
        { hash: window.location.hash },
        (error, authResult) => {
          if (error) {
            resolve(null)
          }
          resolve(authResult)
        },
      )
    }).catch(error => Promise.reject(error))
  }
}

export default new Auth0('ho')
export const auth0Pro = new Auth0(
  'pro',
  getLocaleFromHostname(window.location.hostname),
)
export const auth0Ho = new Auth0(
  'ho',
  getLocaleFromHostname(window.location.hostname),
)

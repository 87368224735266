import { PACKAGE_STATUS_CANDIDATE } from './firm'

export const PRO_DOCUMENTS = [
  {
    title: 'pro.account.pro-documents.cni.title',
    name: 'cni',
    authorizedDocuments: 'pro.account.pro-documents.cni.authorizedDocuments',
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    title: 'pro.account.pro-documents.kbis.title',
    name: 'kbis',
    authorizedDocuments: 'pro.account.pro-documents.kbis.authorizedDocuments',
    meta: {
      expirationDate: false,
      issueDate: true,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    title: 'pro.account.pro-documents.attestation_vigilance.title',
    name: 'attestation_vigilance',
    authorizedDocuments:
      'pro.account.pro-documents.attestation_vigilance.authorizedDocuments',
    meta: {
      expirationDate: false,
      issueDate: true,
      reference: true,
      category: false,
    },
    mandatory: true,
  },
  {
    title: 'pro.account.pro-documents.assurance_rc.title',
    name: 'assurance_rc',
    authorizedDocuments:
      'pro.account.pro-documents.assurance_rc.authorizedDocuments',
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
    },
    mandatory: true,
  },
  {
    title: 'pro.account.pro-documents.assurance_decennale.title',
    name: 'assurance_decennale',
    authorizedDocuments:
      'pro.account.pro-documents.assurance_decennale.authorizedDocuments',
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: false,
    },
    mandatory: false,
  },
  {
    title: 'pro.account.pro-documents.certificate.title',
    name: 'certificate',
    authorizedDocuments:
      'pro.account.pro-documents.certificate.authorizedDocuments',
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: true,
      category: true,
    },
    mandatory: false,
    certificateType: 'cerfa',
  },
  {
    title: 'pro.account.pro-documents.rge.title',
    name: 'rge',
    authorizedDocuments: 'pro.account.pro-documents.rge.authorizedDocuments',
    meta: {
      expirationDate: true,
      issueDate: false,
      reference: false,
      category: true,
    },
    mandatory: false,
    certificateType: 'rge',
  },
]

export const PRO_DOCUMENTS_FORM = 'ProDocumentsForm'
export const FILE_TYPE_BASIC = 'basic'
export const FILE_TYPE_EXPIRING = 'expiring'
export const FIRM_STATUS_CAN_UPDATE_DOCUMENTS = [PACKAGE_STATUS_CANDIDATE]

// @see: https://github.com/jaredpalmer/after.js/blob/master/src/asyncComponent.tsx
import React from 'react'
import isServerSide from 'utils/isServerSide'

/**
 * Returns a new React component, ready to be instantiated.
 * Note the closure here protecting Component, and providing a unique
 * instance of Component to the static implementation of `load`.
 */
export default loader => {
  let Component = null // keep Component in a closure to avoid doing this stuff more than once

  return class AsyncRouteComponent extends React.Component {
    /**
     * Static so that you can call load against an uninstantiated version of
     * this component. This should only be called one time outside of the
     * normal render path.
     */
    static async load() {
      try {
        const ResolvedComponent = await loader()
        Component = ResolvedComponent.default || ResolvedComponent
      } catch (e) {
        if (isServerSide()) {
          throw e
        } else {
          console.error(e)
          window.location.reload(true)
        }
      }
      return Component
    }

    static getInitialProps(ctx) {
      // Need to call the wrapped components getInitialProps if it exists
      if (Component !== null && Component.getInitialProps) {
        return Component.getInitialProps(ctx)
      }
      return Promise.resolve(null)
    }

    state = {
      Component,
    }

    async componentDidMount() {
      await AsyncRouteComponent.load()
      this.updateState()
    }

    updateState = () => {
      // Only update state if we don't already have a reference to the
      // component, this prevent unnecessary renders.
      if (this.state.Component !== Component) {
        this.setState({
          Component,
        })
      }
    }

    render() {
      const { Component: ComponentFromState } = this.state
      if (ComponentFromState) {
        return <ComponentFromState {...this.props} />
      }
      return null
    }
  }
}

const merge = require('lodash/merge')

const getEnvironmentFromUrl = require('../../utils/url')

const hostname = typeof window !== 'undefined' ? window.location.hostname : ''

const currentEnv = getEnvironmentFromUrl(hostname, 'www', 'suividepose.fr')

const config = {
    all: {
        url: 'https://www.local.quotatis.com',
        homePage: 'https://www.quotatis.fr',
        prefix: '+33',
        routes: {
            directory: '/annuaire-artisan',
        },
        sqn: 77450,
        cookiesUrl: {
            default: 'https://www.quotatis.fr/conseils-travaux/charte-de-confidentialite-donnees/',
            ho: 'https://www.quotatis.fr/conseils-travaux/confidentialite-donnees',
        },
        countryLabel: 'country.france',
        contentSiteUrl: 'https://www.quotatis.fr/conseils-travaux/',
        contentSiteUrlPreFooter: 'https://www.quotatis.fr/conseils-travaux/',
        mainMenu: {
            resource: {
                blog: { path: '' },
                guide: { path: 'guide' },
                inspiring: { path: 'inspirations' },
                faq: { path: 'FAQ' },
                tools: { path: 'outils' },
                works: { path: 'les-travaux-et-vous' },
            },
            directory: '/annuaire-artisan',
        },
        genericProjectImages: [
            '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500641857/FR/ChatbotImages/Q2/electricite-domotique-alarmes/installation-electrique-de-l-habitat.jpg',
            '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500641791/FR/ChatbotImages/Q2/construction-extension-renovation/renovation-de-maison-appartement-commerce.jpg',
            '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500641950/FR/ChatbotImages/Q2/experts-et-conseils-avant-travaux/architectev2.jpg',
            '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500641788/FR/ChatbotImages/Q2/construction-extension-renovation/amenagement-de-combles.jpg',
            '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:adv_faces,h_300,w_200/v1505381549/FrontApp/ChatbotImages/shutterstock_408737758.jpg',
            '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1505381593/FrontApp/ChatbotImages/shutterstock_536591941.jpg',
        ],
        genericFirmDetailImage:
            'https://res.cloudinary.com/quotatis/image/upload/v1508320474/FrontApp/proprofile-cover-image.jpg',
        maps: {
            country: 'fr',
        },
        searchSuggestions: {
            primary: [
                {
                    title: 'Fenêtre : fourniture et pose',
                    slug: 'pose-fenetre',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1502376733/FR/ChatbotImages/Q2/fenetres-et-ouvertures-exterieures/fenetre.jpg',
                    tag: 'Projet',
                },
                {
                    title: 'Installation de chaudière / radiateurs',
                    slug: 'installation-chaudiere-radiateur',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/s--IHZ6SHmu--/c_imagga_scale,g_north_west,h_400,w_300/v1500642397/FR/ChatbotImages/Q2/isolation-chauffage-climatisation/chaudiere.jpg',
                    tag: 'Projet',
                },
                {
                    title: 'Isolation thermique des combles',
                    slug: 'isolation-comble',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500642398/FR/ChatbotImages/Q2/isolation-chauffage-climatisation/isolation-par-l-interieur-de-plancher-mur-cloison-combles.jpg',
                    tag: 'Projet',
                },
                {
                    title: 'Salle de bains : fourniture et pose',
                    slug: 'construction-salle-bain',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500641381/FR/ChatbotImages/Q2/amenagement-de-pieces/amenagement-complet-de-salle-de-bains.jpg',
                    tag: 'Projet',
                },
                {
                    title: 'Travaux de peinture (pour particuliers)',
                    slug: 'travaux-peinture',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,h_300,w_200/v1500642631/FR/ChatbotImages/Q2/peinture-sols-et-eclairage/peinture-et-papier-peint.jpg',
                    tag: 'Projet',
                },
            ],
            secondary: [
                {
                    title: 'Rénovation de maison, appartement, commerce',
                    slug: 'renovation-de-maison-appartement-commerce',
                },
                {
                    title: 'Carrelage et parquet',
                    slug: 'carrelage-et-parquet',
                },
                {
                    title: 'Pompe à chaleur',
                    slug: 'pompe-a-chaleur',
                },
            ],
        },
        directory: {
            promotedTrades: [
                {
                    title: 'Menuisier extérieur',
                    slug: '/annuaire-artisan/menuisier-extérieur',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:face,h_300,w_200/v1521558438/FR/TradeImages/menuisier.jpg',
                },
                {
                    title: 'Spécialiste en isolation',
                    slug: '/annuaire-artisan/spécialiste-en-isolation',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:faces,h_300,w_200/v1517311629/FR/TradeImages/specialiste-en-isolation.jpg',
                },
                {
                    title: 'Couvreur - Charpentier',
                    slug: '/annuaire-artisan/couvreur-charpentier',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:faces,h_300,w_200/v1517307280/FR/TradeImages/couvreur-specialiste-toiture.jpg',
                },
                {
                    title: 'Chauffagiste',
                    slug: '/annuaire-artisan/chauffagiste',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:face,h_300,w_200/v1521562780/FR/TradeImages/chauffagiste-specialiste-de-la-climatisation.jpg',
                },
                {
                    title: "Entreprise Générale du Bâtiment - Tout corps d'état",
                    slug:
                        '/annuaire-artisan/entreprise-générale-du-bâtiment-tout-corps-d-état',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:faces,h_300,w_200/v1517246139/FR/TradeImages/entreprise-de-renovation.jpg',
                },
                {
                    title: 'Peintre',
                    slug: '/annuaire-artisan/peintre',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:faces,h_300,w_200/v1517246143/FR/TradeImages/peintre-d-interieur.jpg',
                },
                {
                    title: 'Électricien',
                    slug: '/annuaire-artisan/électricien',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:faces,h_300,w_200/v1517307314/FR/TradeImages/electricien.jpg',
                },
                {
                    title: 'Plombier - Installateur sanitaire - Salle de bains',
                    slug:
                        '/annuaire-artisan/plombier-installateur-sanitaire-salle-de-bains',
                    imageUrl:
                        '//res.cloudinary.com/quotatis/image/upload/c_fill,g_custom:faces,h_300,w_200/v1517311201/FR/TradeImages/plombier-installateur-sanitaire.jpg',
                },
            ],
            tradesFooterLinks: [
                {
                    title: 'Architecte',
                    url: '/annuaire-artisan/architecte',
                },
                {
                    title: 'Canalisateur',
                    url:
                        '/annuaire-artisan/spécialiste-en-assainissement-et-canalisations-vidangeur',
                },
                {
                    title: 'Charpentier',
                    url: '/annuaire-artisan/couvreur-charpentier',
                },
                {
                    title: 'Décorateur',
                    url: '/annuaire-artisan/architecte-d-intérieur-décorateur',
                },
                {
                    title: 'Ébéniste',
                    url: '/annuaire-artisan/ébéniste',
                },
                {
                    title: 'Élagueur',
                    url: '/annuaire-artisan/jardinier-élagueur',
                },
                {
                    title: 'Installateur d’alarme',
                    url: '/annuaire-artisan/spécialiste-en-alarme-électronicien',
                },
                {
                    title: 'Installateur de climatisation reversible',
                    url: '/annuaire-artisan/spécialiste-de-la-climatisation',
                },
                {
                    title: 'Installateur de porte garage',
                    url: '/annuaire-artisan/installateur-de-portails-et-clôtures',
                },
                {
                    title: 'Installateur de volet',
                    url: '/annuaire-artisan/menuisier-extérieur',
                },
                {
                    title: 'Moquettiste',
                    url: '/annuaire-artisan/poseur-de-revêtements-sols-et-murs',
                },
                {
                    title: 'Installateur panneaux photovoltaïques',
                    url: '/annuaire-artisan/spécialiste-en-panneaux-solaires-enr',
                },
                {
                    title: 'Parqueteur',
                    url: '/annuaire-artisan/parquetiste',
                },
                {
                    title: 'Pisciniste',
                    url: '/annuaire-artisan/pisciniste',
                },
                {
                    title: 'Serrurier',
                    url: '/annuaire-artisan/serrurier-métallier-ferronnier',
                },
                {
                    title: 'Storiste',
                    url: '/annuaire-artisan/menuisier-extérieur',
                },
                {
                    title: 'Peintre extérieur',
                    url: '/annuaire-artisan/peintre',
                },
                {
                    title: 'Agenceur',
                    url: '/annuaire-artisan/architecte-d-intérieur-décorateur',
                },
                {
                    title: 'Architecte d’intérieur',
                    url: '/annuaire-artisan/architecte-d-intérieur-décorateur',
                },
                {
                    title: 'Ardoisier',
                    url: '/annuaire-artisan/couvreur-charpentier',
                },
                {
                    title: 'Calorifugeur',
                    url: '/annuaire-artisan/spécialiste-en-isolation',
                },
                {
                    title: 'Terrassier',
                    url: '/annuaire-artisan/spécialiste-en-terrassement-et-drainage',
                },
                {
                    title: 'Couvreur',
                    url: '/annuaire-artisan/couvreur-charpentier',
                },
                {
                    title: 'Plombier 93',
                    url:
                        '/annuaire-artisan/plombier-installateur-sanitaire-salle-de-bains/île-de-france/seine-saint-denis',
                },
                {
                    title: 'Plaquiste',
                    url: '/annuaire-artisan/plâtrier-plaquiste',
                },
                {
                    title: 'Electricien',
                    url: '/annuaire-artisan/électricien',
                },
                {
                    title: 'Antenniste',
                    url: '/annuaire-artisan/antenniste',
                },
            ],
        },
        preFooter: {
            links: [
                [
                    {
                        key: 'window',
                        src: 'guide/fenetres-portes-volets/fenetre/',
                    },
                    {
                        key: 'shutter',
                        src: 'guide/fenetres-portes-volets/volets/',
                    },
                    {
                        key: 'roof_insulation',
                        src: 'guide/isolation/isolation-des-combles/',
                    },
                ],
                [
                    {
                        key: 'wood_heating',
                        src: 'guide/chauffage/chauffage-au-bois/',
                    },
                    {
                        key: 'heat_pumps',
                        src: 'guide/chauffage/pompe-a-chaleur/',
                    },
                    {
                        key: 'solar_heating',
                        src: 'guide/chauffage/le-chauffage-solaire/',
                    },
                ],
                [
                    {
                        key: 'heating_gas_oil',
                        src: 'guide/chauffage/chauffage-gaz-fioul/',
                    },
                    {
                        key: 'electric_heating',
                        src: 'guide/chauffage/chauffage-electrique/',
                    },
                    {
                        key: 'fireplaces',
                        src: 'guide/chauffage/cheminee-poele/',
                    },
                ],
                [
                    {
                        key: 'heat_emitters',
                        src: 'guide/chauffage/emetteurs-de-chaleur/',
                    },
                    {
                        key: 'domestic_hot_water_production',
                        src: 'guide/chauffage/production-deau-chaude-sanitaire/',
                    },
                ],
            ],
        },
        footer: {
            corporate: {
                col1: [
                    {
                        key: 'contact',
                        src: '/contact-form.html',
                        target: '_blank',
                    },
                    {
                        key: 'about',
                        src: 'https://www.quotatis.fr/qui-sommes-nous/',
                        contentSite: false,
                    },
                    {
                        key: 'job',
                        src:
                            'https://www.welcometothejungle.com/fr/companies/quotatis/jobs',
                        target: '_blank',
                    },
                    {
                        key: 'terms_of_sale',
                        src: 'cgu/',
                        contentSite: true,
                    },
                    {
                        key: 'privacy_policy',
                        src: 'charte-de-confidentialite-donnees/',
                        contentSite: true,
                    },
                    {
                        key: 'legal_notice',
                        src: 'mentions-legales/',
                        contentSite: true,
                    },
                    {
                        key: 'quality_charter',
                        src: 'charte-de-qualite-quotatis/',
                        contentSite: true,
                    },
                ],
                col2: [
                    {
                        key: 'rating_review',
                        src: 'cgu-avis-et-notation/',
                        contentSite: true,
                    },
                    {
                        key: 'guides',
                        src: '',
                        contentSite: true,
                        target: '_blank',
                    },
                    {
                        key: 'directory',
                        src: '/annuaire-artisan',
                    },
                    {
                        key: 'qpro',
                        src: '/pro',
                        target: '_blank',
                    },
                    {
                        key: 'adeo',
                        src: 'http://www.adeo.com/',
                        target: '_blank',
                    },
                    {
                        key: 'quote',
                        src: '/travaux-entreprise.html',
                        target: '_blank',
                    },
                    {
                        key: 'trades',
                        src: 'artisan-installateur-poseur-travaux.php',
                        target: '_blank',
                    },
                    {
                        key: 'tools',
                        src: 'outils',
                        contentSite: true,
                        target: '_blank',
                    },
                ],
            },
            social: {
                facebook: 'https://www.facebook.com/Quotatis.FR/',
                twitter: 'https://twitter.com/quotatisfr',
                linkedin: 'https://www.linkedin.com/company/quotatis',
                instagram: 'https://www.instagram.com/quotatis/',
            },
        },
        pro: {
            urls: {
                home: '/pro',
                login: '/pro/login',
                signUp: 'https://forfaits.quotatis.fr/comment-ca-marche/',
                blog: 'https://lecoindespros.quotatis.fr',
                registration: '/pro/inscription-annuaire',
            },
            salesPhone: '0177867191',
            homePage: {
                backgroundImage: '/pro/fr/testimonial-background_photo-2.png',
                proImage: '/pro/fr/testimonial-pro_photo-2.png',
                textureImage: '/pro/fr/paint_pattern_light.svg',
                offers: [
                    {
                        key: 'offer_1',
                        type: 'free',
                    },
                    {
                        key: 'offer_2',
                        type: 'local',
                    },
                    {
                        key: 'offer_3',
                        type: 'connect',
                    },
                ],
                partnerLogos: [
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181381/partner/logo-LM.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181382/partner/logo-bricoman.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181383/partner/logo-seloger.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181382/partner/logo-figaro.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181383/partner/logo-securitas.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181381/partner/logo-express.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181382/partner/logo-entrepreneur.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181382/partner/logo-murprotec.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181383/partner/logo-veranda.svg',
                    'https://res.cloudinary.com/quotatis/image/upload/v1565181384/partner/logo-waterair.svg',
                ],
            },
            signUpFormRadius: {
                radius: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
                initialValue: '20000',
            },
            preFooter: {
                links: [
                    [
                        {
                            text: 'Appel d’offre peinture',
                            src: '/appel-offre/peinture.html',
                        },
                        {
                            text: 'Appel d’offre chauffage',
                            src: '/appel-offre/chauffage.html',
                        },
                        {
                            text: 'Appel d’offre isolation thermique',
                            src: '/appel-offre/isolation.html',
                        },
                        {
                            text: 'Appel d’offre construction de maison',
                            src: '/appel-offre/construction.html',
                        },
                    ],
                    [
                        {
                            text: 'Appel d’offre rénovation',
                            src: '/appel-offre/renovation.html',
                        },
                        {
                            text: 'Appel d’offre cuisine',
                            src: '/appel-offre/cuisine.html',
                        },
                        {
                            text: 'Appel d’offre salle de bain',
                            src: '/appel-offre/salle+de+bain.html',
                        },
                        {
                            text: 'Appel d’offre pompe à chaleur',
                            src: '/appel-offre/pompe+a+chaleur.html',
                        },
                    ],
                    [
                        {
                            text: 'Appel d’offre toiture',
                            src: '/appel-offre/toiture.html',
                        },
                        {
                            text: 'Appel d’offre maçonnerie',
                            src: '/appel-offre/maconnerie.html',
                        },
                        {
                            text: 'Appel d’offre fenêtre',
                            src: '/appel-offre/fenetre.html',
                        },
                        {
                            text: 'Appel d’offre climatisation',
                            src: '/appel-offre/climatisation.html',
                        },
                    ],
                    [
                        {
                            text: 'Appel d’offre panneaux voltaïques',
                            src: '/appel-offre/panneaux+photovoltaiques.html',
                        },
                        {
                            text: 'Appel d’offre chaudière',
                            src: '/appel-offre/chaudiere.html',
                        },
                        {
                            text: 'Recherche chantiers plomberie',
                            src: '/appel-offre/plomberie.html',
                        },
                        {
                            text: 'Appel d’offre bâtiment',
                            src: '/liste-appel-offre.html',
                        },
                    ],
                ],
            },
            footer: {
                corporate: {
                    col1: [
                        {
                            key: 'quotatis',
                            src: '/pro',
                            target: '_blank',
                        },
                        {
                            key: 'partener',
                            src: 'https://partenaires.quotatis.fr',
                            target: '_blank',
                        },
                    ],
                    col2: [
                        {
                            key: 'terms_of_sale',
                            src: 'https://www.quotatis.fr/conseils-travaux/cgu-pro',
                        },
                        {
                            key: 'legal_notice',
                            src: 'https://www.quotatis.fr/conseils-travaux/mentions-legales',
                        },
                        {
                            key: 'privacy_policy',
                            src:
                                'https://www.quotatis.fr/conseils-travaux/charte-de-confidentialite-donnees',
                        },
                        {
                            key: 'contact',
                            src: '/espace-pro/contact.html',
                            target: '_blank',
                        },
                        {
                            key: 'about',
                            src: 'https://www.quotatis.fr/qui-sommes-nous',
                            target: '_blank',
                        },
                    ],
                },
            },
        },
        proLegacyUrls: {
            rating: '/espace-pro/moncompte/notation',
            options: '/espace-pro/moncompte/configuration',
            help: '/espace-pro/moncompte/messagerie',
            projects: '/espace-pro/moncompte/mesprojets',
            informations: '/espace-pro/moncompte/entreprise',
            bills: '/espace-pro/moncompte/mesfactures',
            creditCard: '/espace-pro/transaction.html?t=cc',
            documents: '/espace-pro/moncompte/documents',
        },
        legacyWidget: {
            url: 'https://widget-fr.legacy.quotatis.net/js/prefilled-form-load.js',
            sqn: 76935,
            theme: 'theme2',
        },
        auth0: {
            pro: {
                clientID: '72om4BypFbGXbtnunhOI5P0zExySocuE',
                audience: 'https://auth0.qa.quotatis.fr/logical-api',
            },
        },
    },
    development: {
        intercom: {
            appId: 'swltzwn8',
        },
    },
    mocks: {
        intercom: {
            appId: 'fakeId',
        },
    },
    qa1: {
        auth0: {
            pro: {
                clientID: 'eu3bgSbEmO7MiR75hQQTIIvjW6aBAtkZ',
                audience: 'https://auth0.qa1.quotatis.fr/logical-api',
            },
        },
    },
    qa: {
        auth0: {
            pro: {
                clientID: '72om4BypFbGXbtnunhOI5P0zExySocuE',
                audience: 'https://auth0.qa.quotatis.fr/logical-api',
            },
        },
    },
    preprod: {
        auth0: {
            pro: {
                clientID: '3mtoZt9D6xhzs8xOTa671chYYx4Xeo00',
                audience: 'https://auth0.preprod.quotatis.fr/logical-api',
            },
        },
        intercom: {
            appId: 'swltzwn8',
        },
    },
    prod: {
        legacyWidget: {
            url: 'https://widget.quotatis.fr/js/prefilled-form-load.js',
            sqn: 76935,
            theme: 'theme2',
        },
        auth0: {
            pro: {
                clientID: '2YNWAhYoDMAApQ2l6LoBem7k5FWfujGb',
                audience: 'https://auth0.prod.quotatis.fr/logical-api',
            },
        },
        intercom: {
            appId: 'hkrrf3yu',
        },
    },
}

module.exports = merge(config.all, config[currentEnv])
